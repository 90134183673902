import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { SizedIframe } from '@pafcloud/block-components';
import type { IframeBlock_block$key } from './__generated__/IframeBlock_block.graphql';

const blockFragment = graphql`
  fragment IframeBlock_block on IframeBlock {
    url
  }
`;

type Props = {
  block: IframeBlock_block$key;
};

const IframeBlock: FC<Props> = (props) => {
  const block = useFragment(blockFragment, props.block);
  return <SizedIframe src={block.url} allow="fullscreen" />;
};

export default IframeBlock;
