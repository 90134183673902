import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { LoyaltyProgramProgress } from '@pafcloud/block-components';
import { SafeQueryRenderer } from '@pafcloud/relay-helpers';
import { useIsSSR } from '@pafcloud/react-hook-utils';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { LoyaltyHeroSection } from '@pafcloud/block-components/src/loyalty-program/parts';
import { useTranslation } from '@pafcloud/i18n';
import { MarkdownParser } from '@pafcloud/base-components';
import type { LoyaltyProgramProgressBlock_block$key } from './__generated__/LoyaltyProgramProgressBlock_block.graphql';
import type { LoyaltyProgramProgressBlockQuery } from './__generated__/LoyaltyProgramProgressBlockQuery.graphql';

const blockFragment = graphql`
  fragment LoyaltyProgramProgressBlock_block on LoyaltyProgramProgressBlock {
    ...LoyaltyProgramProgress_block
  }
`;

const loyaltyProgramProgressBlockQuery = graphql`
  query LoyaltyProgramProgressBlockQuery {
    player {
      age
      ...LoyaltyProgramProgress_player
    }
  }
`;

type Props = {
  block: LoyaltyProgramProgressBlock_block$key;
};

const LoyaltyProgramProgressBlock: FC<Props> = ({ block }) => {
  const blockData = useFragment(blockFragment, block);
  const { t } = useTranslation('loyalty-program');
  const isSSR = useIsSSR();

  if (isSSR) {
    return null;
  }

  if (block == null) {
    return null;
  }

  return (
    <SafeQueryRenderer<LoyaltyProgramProgressBlockQuery>
      query={loyaltyProgramProgressBlockQuery}
      fetchPolicy="store-or-network"
      render={({ props }) => {
        const player = props.player;

        if (player == null) {
          return null;
        }

        if ($buildEnv.market === 'spain' && player?.age != null && player.age < 26) {
          return (
            <LoyaltyHeroSection>
              <MarkdownParser>{t('player-excluded')}</MarkdownParser>
            </LoyaltyHeroSection>
          );
        }

        return <LoyaltyProgramProgress block={blockData} player={player} />;
      }}
    />
  );
};

export default LoyaltyProgramProgressBlock;
