import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { InformationContainer } from '@pafcloud/block-components';
import type { InformationContainerBlock_block$key } from './__generated__/InformationContainerBlock_block.graphql';

const blockFragment = graphql`
  fragment InformationContainerBlock_block on InformationContainerBlock {
    heading
    blockLayout
    description
    portrait {
      src
      altText
      icon
    }
  }
`;

type Props = {
  block: InformationContainerBlock_block$key;
};

const InformationContainerBlock: FC<Props> = (props) => {
  const block = useFragment(blockFragment, props.block);
  const { heading, description, portrait, blockLayout } = block;

  const image = portrait.src ? { src: portrait.src, altText: portrait.altText } : undefined;
  const icon = portrait.icon;

  return (
    <InformationContainer
      heading={heading}
      description={description}
      portraitIcon={icon}
      layout={blockLayout}
      image={image}
    />
  );
};

export default InformationContainerBlock;
