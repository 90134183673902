import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { Accordion } from '@pafcloud/base-components';
import { TableBlock } from '@pafcloud/block-components';
import { RichTextBlock } from '@pafcloud/rich-text';
import { removeNullValues } from '@pafcloud/collection-utils';
import type { AccordionBlock_block$key } from './__generated__/AccordionBlock_block.graphql';

const blockComponents = {
  RichTextBlock,
  TableBlock,
};

const blockFragment = graphql`
  fragment AccordionBlock_block on AccordionBlock {
    header
    blocks {
      __typename
      ...RichTextBlock_block
      ...TableBlock_block
    }
  }
`;

const getBlockComponent = (type: string, components: typeof blockComponents) => {
  if (type in components) {
    return components[type as keyof typeof components];
  }
  return null;
};

type Props = {
  block: AccordionBlock_block$key;
};

const AccordionBlock: FC<Props> = (props) => {
  const { header, blocks } = useFragment(blockFragment, props.block);

  return (
    <Accordion title={header}>
      {removeNullValues(blocks).map((block, index) => {
        const Component = getBlockComponent(block.__typename, blockComponents);
        if (Component) {
          return <Component key={index} block={block} />;
        }
        return null;
      })}
    </Accordion>
  );
};

export default AccordionBlock;
