/**
 * @generated SignedSource<<6fd890682a685f4856dbddfc79da5558>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type BlockLayout = "HORIZONTAL" | "VERTICAL";
import { FragmentRefs } from "relay-runtime";
export type InformationContainerBlock_block$data = {
  readonly blockLayout: BlockLayout;
  readonly description: string;
  readonly heading: string;
  readonly portrait: {
    readonly altText: string | null;
    readonly icon: string | null;
    readonly src: string | null;
  };
  readonly " $fragmentType": "InformationContainerBlock_block";
};
export type InformationContainerBlock_block$key = {
  readonly " $data"?: InformationContainerBlock_block$data;
  readonly " $fragmentSpreads": FragmentRefs<"InformationContainerBlock_block">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InformationContainerBlock_block",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "heading",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "blockLayout",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Portrait",
      "kind": "LinkedField",
      "name": "portrait",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "src",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "altText",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "icon",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "InformationContainerBlock",
  "abstractKey": null
};

(node as any).hash = "8192f8fb827dad8cebc8cba946f5067e";

export default node;
