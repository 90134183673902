import type { FC } from 'react';
import { usePaginationFragment, graphql } from 'react-relay/hooks';
import styled from '@emotion/styled';
import { GridList, Section } from '@pafcloud/base-components';
import { GameListTitle } from '@pafcloud/game-components';
import { MultiplayerBingoItem } from '../bingo-item';
import type { MultiplayerBingoList_bingoGameList$key } from './__generated__/MultiplayerBingoList_bingoGameList.graphql';
import { useBingo } from './useBingo';

const MultiplayerBingoSection = styled.section({
  paddingLeft: 'var(--full-width-margin)',
  paddingRight: 'var(--full-width-margin)',
  marginBottom: 32,
});

const bingoGameListFragment = graphql`
  fragment MultiplayerBingoList_bingoGameList on MultiplayerBingoList
  @argumentDefinitions(count: { type: Int, defaultValue: 20 }, cursor: { type: String })
  @refetchable(queryName: "MultiplayerBingoListPaginationQuery") {
    items(first: $count, after: $cursor) @connection(key: "MultiplayerBingoList_bingoGameList_items") {
      edges {
        node {
          id
          ...MultiplayerBingoItem_bingoItem
        }
      }
    }
  }
`;

type MultiplayerBingoListProps = {
  bingoGameList: MultiplayerBingoList_bingoGameList$key;
  title?: string | null;
};

export const MultiplayerBingoList: FC<MultiplayerBingoListProps> = ({ title, ...props }) => {
  const bingoGameList = usePaginationFragment(bingoGameListFragment, props.bingoGameList);

  useBingo({
    refetch: () => {
      bingoGameList.refetch({}, { fetchPolicy: 'store-and-network' });
    },
  });

  if (bingoGameList.data.items.edges.length === 0) {
    return null;
  }

  return (
    <Section>
      <MultiplayerBingoSection className="full-width">
        {title != null && <GameListTitle title={title} />}

        <GridList>
          {bingoGameList.data.items.edges.map(({ node }) => (
            <MultiplayerBingoItem key={node.id} bingoItem={node} />
          ))}
        </GridList>
      </MultiplayerBingoSection>
    </Section>
  );
};
