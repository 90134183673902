import type { FC } from 'react';
import styled from '@emotion/styled';
import { GroupedTab, ScrollableTabs, Tabs } from '@pafcloud/base-components';
import type { GraphqlTypes as gt } from '@pafcloud/graphql-schema/types';
import { dispatchTrackingEvent } from '@pafcloud/tracking';
import { isType } from '@pafcloud/collection-utils';
import { FontTextSize } from '@pafcloud/style';
import { GameListFilterLink } from './TagFilterTab';
import { GameCountColors } from './game-count-colors';

type Props = {
  showAllButtonText: string;
  tags: (gt.Tag | gt.TagGroup)[];
  selectedTag: gt.Tag | null;
};

const GameCount = styled.span({
  padding: '0.2rem 0.3rem',
  marginLeft: 'auto',
  fontSize: FontTextSize.Tiny,
  color: GameCountColors.Color,
  backgroundColor: GameCountColors.BackgroundColor,
  borderRadius: '0.4rem',
  display: 'flex',
  alignItems: 'center',
});

export const TagFilter: FC<Props> = ({ showAllButtonText, tags, selectedTag }) => {
  const slug = selectedTag?.slug ?? '';

  // Push new route, changing query param will trigger effect
  const setTag = (tag?: gt.Tag, category?: gt.TagGroup) => {
    if (tag && tag !== selectedTag) {
      dispatchTrackingEvent('gameList-filter-click', {
        gameList: {
          filter: {
            tag: tag.slug,
            category: category?.title.toLowerCase(),
          },
        },
      });
    }
  };

  return (
    <ScrollableTabs>
      <Tabs>
        <GameListFilterLink role="tab" selected={slug === ''} onClick={() => setTag()}>
          {showAllButtonText}
        </GameListFilterLink>
        {tags.map((tag) => {
          if (isType(tag, 'TagGroup')) {
            const selected = tag.tags.some((t) => t.slug === slug);
            return (
              <GroupedTab key={tag.title} title={tag.title} selected={selected}>
                {(close) =>
                  tag.tags.map((t) => (
                    <li key={t.slug}>
                      <GameListFilterLink
                        tag={t}
                        selected={t.slug === slug}
                        onClick={() => {
                          setTag(t, tag);
                          close();
                        }}
                      >
                        {t.title}
                        <GameCount>{t.gameCount > 1000 ? '1000+' : t.gameCount}</GameCount>
                      </GameListFilterLink>
                    </li>
                  ))
                }
              </GroupedTab>
            );
          }
          if (isType(tag, 'Tag')) {
            return (
              <GameListFilterLink
                key={tag.slug}
                role="tab"
                tag={tag}
                selected={tag.slug === slug}
                onClick={() => setTag(tag)}
              >
                {tag.title}
              </GameListFilterLink>
            );
          }
          return null;
        })}
      </Tabs>
    </ScrollableTabs>
  );
};
