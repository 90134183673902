import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { withBuildEnv } from '@pafcloud/base-components';
import { Video as Video_default } from './backgroundStyles.default';
import { Video as Video_clean } from './backgroundStyles.clean';
import { Video as Video_golden } from './backgroundStyles.golden';
import { Video as Video_goldenV2 } from './backgroundStyles.goldenV2';
import { Video as Video_dreams } from './backgroundStyles.dreams';

export const Video = withBuildEnv(() => {
  if ($buildEnv.theme === 'clean') {
    return Video_clean;
  }
  if ($buildEnv.theme === 'clean-blue') {
    return Video_clean;
  }
  if ($buildEnv.theme === 'golden') {
    return Video_golden;
  }
  if ($buildEnv.theme === 'goldenV2') {
    return Video_goldenV2;
  }
  if ($buildEnv.theme === 'quirky') {
    return Video_golden;
  }
  if ($buildEnv.theme === 'dreams') {
    return Video_dreams;
  }
  return Video_default;
});
