import type { FC } from 'react';
import Head from 'next/head';
import { graphql, useFragment } from 'react-relay/hooks';
import dynamic from 'next/dynamic';
import { removeNullValues } from '@pafcloud/collection-utils';
import { VisuallyHiddenHeading } from '@pafcloud/base-components';
import { AccessGuardLoadingIndicator } from '@pafcloud/block-components/src/access-guard/guards/AccessGuardLoadingIndicator';
import { PageBlockRenderer } from '../../blocks/PageBlockRenderer';
import type { SanityPage_page$key } from './__generated__/SanityPage_page.graphql';

const AgeLimitGuard = dynamic(
  () => import('@pafcloud/block-components/src/access-guard/guards/age-limit-guard/AgeLimitGuard'),
  { loading: () => <AccessGuardLoadingIndicator /> },
);

const pageFragment = graphql`
  fragment SanityPage_page on Page {
    title
    requiredAge
    seo {
      disableInvisibleHeading
      headingText
    }
    metadata {
      title
      canonical
      description
      robots
    }
    blocks {
      ...PageBlockRenderer_blocks
    }
  }
`;

type Props = {
  page: SanityPage_page$key;
};

export const SanityPage: FC<Props> = (props) => {
  const page = useFragment(pageFragment, props.page);

  if (page.requiredAge) {
    return <AgeLimitGuard requiredAge={page.requiredAge} />;
  }

  const { metadata, title } = page;

  return (
    <>
      <Head>
        <title>{metadata?.title ?? title}</title>
        {metadata?.robots && <meta name="robots" content={metadata.robots} />}
        {metadata?.canonical && <link rel="canonical" key="canonical" href={metadata.canonical} />}
        {metadata?.description && <meta name="description" content={metadata.description} />}
      </Head>
      <PageBlockRenderer blocks={removeNullValues(page.blocks)} />
      {!page.seo?.disableInvisibleHeading && (
        <VisuallyHiddenHeading>{page.seo?.headingText ?? title}</VisuallyHiddenHeading>
      )}
    </>
  );
};
