import type { Offering as ko } from '@pafcloud/kambi-api';
import {
  fetchOffering,
  filterBetOfferResponseByTag,
  formatBetOfferResponse,
  randomBetOfferResponse,
} from '@pafcloud/kambi-api';

type KambiOffering = {
  baseUrl: string;
  lang: string;
  market: string;
};

type BetOfferParams = {
  excludeLive?: boolean;
  excludeOngoing?: boolean;
  excludePrematch?: boolean;
  maxNumberEvents?: number;
  onlyMain?: boolean;
};

export async function fetchBetOfferParticipant(
  kambiOffering: KambiOffering,
  participantId: string,
  params: BetOfferParams,
) {
  const betOffersUrl = new URL(`${kambiOffering.baseUrl}/betoffer/participant/${participantId}.json`);
  betOffersUrl.searchParams.set('market', kambiOffering.market);
  betOffersUrl.searchParams.set('lang', kambiOffering.lang);
  betOffersUrl.searchParams.set('type', '2');
  betOffersUrl.searchParams.set('includeParticipants', 'true');
  for (const [key, value] of Object.entries(params)) {
    betOffersUrl.searchParams.set(key, value.toString());
  }

  const betOfferResponse = await fetchOffering<ko.BetOfferResponse>(betOffersUrl);

  // The participant endpoint does not have a 'onlyMain' search param,
  // so we need to find the bet offer with the 'MAIN' tag
  if (params.onlyMain) {
    const { betOffers, events } = filterBetOfferResponseByTag(betOfferResponse);

    const { liveData } = await fetchEventLiveData(kambiOffering, events[0].id.toString());
    return { betOffers, events, liveData };
  }

  const { betOffers, events } = formatBetOfferResponse(betOfferResponse);

  const { liveData } = await fetchEventLiveData(kambiOffering, events[0].id.toString());
  return { betOffers, events, liveData };
}

export async function fetchBetOfferEvent(kambiOffering: KambiOffering, eventId: string, params: BetOfferParams) {
  const betOffersUrl = new URL(`${kambiOffering.baseUrl}/betoffer/event/${eventId}.json`);
  betOffersUrl.searchParams.set('market', kambiOffering.market);
  betOffersUrl.searchParams.set('lang', kambiOffering.lang);
  betOffersUrl.searchParams.set('onlyMain', 'true');
  betOffersUrl.searchParams.set('type', '2');
  betOffersUrl.searchParams.set('includeParticipants', 'true');
  for (const [key, value] of Object.entries(params)) {
    betOffersUrl.searchParams.set(key, value.toString());
  }
  const betOfferResponse = await fetchOffering<ko.BetOfferResponse>(betOffersUrl);
  const { betOffers, events } = formatBetOfferResponse(betOfferResponse);

  const { liveData } = await fetchEventLiveData(kambiOffering, eventId);
  return { betOffers, events, liveData };
}

export async function fetchRandomBetOfferGroup(
  kambiOffering: KambiOffering,
  groupId: string,
  params: BetOfferParams = {},
) {
  const betOffersUrl = new URL(`${kambiOffering.baseUrl}/betoffer/group/${groupId}.json`);
  betOffersUrl.searchParams.set('market', kambiOffering.market);
  betOffersUrl.searchParams.set('lang', kambiOffering.lang);
  betOffersUrl.searchParams.set('onlyMain', 'true');
  betOffersUrl.searchParams.set('type', '2');
  betOffersUrl.searchParams.set('includeParticipants', 'true');
  for (const [key, value] of Object.entries(params)) {
    betOffersUrl.searchParams.set(key, value.toString());
  }

  const betOfferResponse = await fetchOffering<ko.BetOfferResponse>(betOffersUrl);
  const { betOffers, events } = randomBetOfferResponse(betOfferResponse);

  const { liveData } = await fetchEventLiveData(kambiOffering, events[0].id.toString());
  return { betOffers, events, liveData };
}

export async function fetchBetOfferGroup(kambiOffering: KambiOffering, groupId: string, params: BetOfferParams = {}) {
  const betOffersUrl = new URL(`${kambiOffering.baseUrl}/betoffer/group/${groupId}.json`);
  betOffersUrl.searchParams.set('market', kambiOffering.market);
  betOffersUrl.searchParams.set('lang', kambiOffering.lang);
  betOffersUrl.searchParams.set('onlyMain', 'true');
  betOffersUrl.searchParams.set('type', '2');
  betOffersUrl.searchParams.set('includeParticipants', 'true');
  for (const [key, value] of Object.entries(params)) {
    betOffersUrl.searchParams.set(key, value.toString());
  }

  const betOfferResponse = await fetchOffering<ko.BetOfferResponse>(betOffersUrl);
  const { betOffers, events } = formatBetOfferResponse(betOfferResponse);

  const eventIds = events.reduce<number[]>((acc, event) => {
    if (event.state === 'STARTED') {
      acc.push(event.id);
    }
    return acc;
  }, []);

  const { liveData } = await fetchEventLiveData(kambiOffering, eventIds.join(','));
  return { betOffers, events, liveData };
}

async function fetchEventLiveData(kambiOffering: KambiOffering, eventId: string) {
  if (!eventId.length) {
    return { liveData: [] };
  }

  const eventLiveDataUrl = new URL(`${kambiOffering.baseUrl}/event/livedata/${eventId}`);
  eventLiveDataUrl.searchParams.set('market', kambiOffering.market);
  eventLiveDataUrl.searchParams.set('lang', kambiOffering.lang);

  const liveDataResponse = await fetchOffering<ko.LiveDataResponseMultiEventDto>(eventLiveDataUrl);
  return liveDataResponse;
}
