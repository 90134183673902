/**
 * @generated SignedSource<<d1c420c417945265ab797be80967e9aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Icon = "ACCOUNT" | "AGE" | "ARROW_LEFT_FAT" | "AWARD" | "BACCARAT" | "BASKETBALL" | "BELT" | "BETTING" | "BINGO" | "BINGO_BALL" | "BLACKJACK" | "BOMB" | "BONUS" | "CARE" | "CASINO_LIVE" | "CHAT_BUBBLES" | "CHECK" | "CHEVRON_DOUBLE_RIGHT" | "CHEVRON_RIGHT" | "CHEVRON_RIGHT_FAT" | "CHEVRON_RIGHT_THIN_ROUNDED" | "CHRISTMAS" | "CLOCK" | "CLOSE_FAT" | "CLOSE_FAT_ROUNDED" | "CLOSE_THIN_ROUNDED" | "COIN" | "COINS" | "COLLECTION" | "DICE" | "DOWNLOAD" | "EMAIL" | "EXCLAMATION" | "EXTERNAL_LINK" | "FANTASY_SPORT" | "FULLSCREEN" | "FULLSCREEN_CLOSE" | "GIFT" | "GLOBE" | "GRAPH" | "HAMBURGER" | "HISTORY" | "HOME" | "HOT" | "HOT_BALLOON" | "HOURGLASS" | "INFO" | "INSURANCE" | "JACKPOT" | "JACKPOT_POOLS" | "KEBAB" | "LIGHTBULB" | "LOCK" | "LOGOUT" | "MAINTENANCE" | "MESSAGE" | "MINUS" | "NOTES" | "NOTIFICATION" | "OPEN_BANKING" | "OTHER_GAMES" | "PACKAGE" | "PAUSE" | "PHONE" | "PLAY" | "PLAYERS" | "PLUS" | "POKER" | "POKER_HAND" | "ROOF" | "ROULETTE" | "ROUNDED_SQUARE" | "RULES" | "SEARCH" | "SETTINGS" | "SHARE" | "SHIELD" | "SLIDERS" | "SLOTS" | "SLOT_MACHINE" | "SOUND_OFF" | "SOUND_ON" | "STAR_BADGE" | "STOP_WATCH" | "SUPPORT" | "SUPPORT_CHAT" | "SUPPORT_HANDS" | "TICKET_GRID" | "TICKET_SPECIAL" | "TOGGLE_SEE" | "TOGGLE_UNSEE" | "TOOLS" | "TRASH" | "TROPHY" | "TURNOVER_CONTRIBUTION" | "UPLOAD" | "VIDEO_POKER" | "WALLET";
import { FragmentRefs } from "relay-runtime";
export type PromotionCard_promotion$data = {
  readonly button: {
    readonly ariaLabel: string | null;
    readonly customLink: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"useGetFlowUrl_button">;
  };
  readonly details: {
    readonly iconName: Icon | null;
    readonly text: string;
  };
  readonly sausage: {
    readonly iconName: Icon | null;
    readonly text: string;
  } | null;
  readonly thumbnail: string;
  readonly title: string;
  readonly " $fragmentType": "PromotionCard_promotion";
};
export type PromotionCard_promotion$key = {
  readonly " $data"?: PromotionCard_promotion$data;
  readonly " $fragmentSpreads": FragmentRefs<"PromotionCard_promotion">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "iconName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PromotionCard_promotion",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PromotionCardDetails",
      "kind": "LinkedField",
      "name": "sausage",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PromotionCardDetails",
      "kind": "LinkedField",
      "name": "details",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Button",
      "kind": "LinkedField",
      "name": "button",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ariaLabel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customLink",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useGetFlowUrl_button"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PromotionCard",
  "abstractKey": null
};
})();

(node as any).hash = "eb3ee59e8feb80eb32fe132919ca446d";

export default node;
