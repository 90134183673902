/**
 * @generated SignedSource<<93c73a1540d2c2ae73977e2af7a562c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccordionBlock_block$data = {
  readonly blocks: ReadonlyArray<{
    readonly __typename: string;
    readonly " $fragmentSpreads": FragmentRefs<"RichTextBlock_block" | "TableBlock_block">;
  } | null>;
  readonly header: string;
  readonly " $fragmentType": "AccordionBlock_block";
};
export type AccordionBlock_block$key = {
  readonly " $data"?: AccordionBlock_block$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccordionBlock_block">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccordionBlock_block",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "header",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "blocks",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RichTextBlock_block"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TableBlock_block"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AccordionBlock",
  "abstractKey": null
};

(node as any).hash = "11674031a9484c3d3decd5b8327b69c5";

export default node;
