import styled from '@emotion/styled';
import type { FC } from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import { ButtonLink, Heading, PrimaryHeading } from '@pafcloud/base-components';
import { Breakpoint } from '@pafcloud/style';
import type { Promotions_block$key } from './__generated__/Promotions_block.graphql';
import { PromotionsRow } from './PromotionsRow';
import { PromotionsGrid } from './PromotionsGrid';

const blockFragment = graphql`
  fragment Promotions_block on PromotionsBlock {
    title
    link {
      text
      url
      ariaLabel
    }
    displayType
    promotions {
      ...PromotionsRow_promotions
      ...PromotionsGrid_promotions
    }
  }
`;

const PromotionSection = styled.section({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const Title = styled(Heading)<{ center: boolean }>(PrimaryHeading, ({ center }) => ({
  alignSelf: 'flex-start',
  marginRight: 'unset',
  marginBottom: 16,
  paddingInline: 'var(--full-width-margin)',

  [Breakpoint.HDScreenOrLarger]: {
    alignSelf: center ? 'center' : 'flex-start',
  },
}));

const Link = styled(ButtonLink)({
  display: 'inline-flex',
  margin: '24px auto 0',
});

type Props = {
  block: Promotions_block$key;
};

export const Promotions: FC<Props> = ({ block }) => {
  const { title, promotions, displayType, link } = useFragment(blockFragment, block);

  if (promotions.length === 0) {
    return null;
  }

  return (
    <PromotionSection className="full-width">
      {title && <Title center={promotions.length <= 2}>{title}</Title>}

      {displayType === 'ROW' && <PromotionsRow promotions={promotions} />}
      {displayType === 'GRID' && <PromotionsGrid promotions={promotions} />}

      {link && (
        <Link href={link.url} aria-label={link.ariaLabel ?? ''} variant="secondary" size="default" colorstyle="accent">
          {link.text}
        </Link>
      )}
    </PromotionSection>
  );
};
