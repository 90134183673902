import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { PosterGameList } from '@pafcloud/game-components';
import type { PosterGameListBlock_block$key } from './__generated__/PosterGameListBlock_block.graphql';

const blockFragment = graphql`
  fragment PosterGameListBlock_block on PosterGameListBlock {
    ...PosterGameList_block
  }
`;

type Props = {
  block: PosterGameListBlock_block$key;
};

const PosterGameListBlock: FC<Props> = (props) => {
  const block = useFragment(blockFragment, props.block);
  return <PosterGameList block={block} />;
};

export default PosterGameListBlock;
