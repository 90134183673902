import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { GameShortcut } from '@pafcloud/block-components';
import type { GameShortcutBlock_block$key } from './__generated__/GameShortcutBlock_block.graphql';

const blockFragment = graphql`
  fragment GameShortcutBlock_block on GameShortcutBlock {
    figure {
      icon
    }
    href
    linkText
    ariaLabel
  }
`;

type Props = {
  block: GameShortcutBlock_block$key;
};

const GameShortcutBlock: FC<Props> = (props) => {
  const block = useFragment(blockFragment, props.block);
  const { figure, href, linkText, ariaLabel } = block;

  if (href == null || linkText == null || ariaLabel == null) {
    return null;
  }

  return <GameShortcut figureIcon={figure.icon} link={{ href, linkText, ariaLabel }} />;
};

export default GameShortcutBlock;
