/**
 * @generated SignedSource<<aa22101f7998e739bf6c5b2a962862b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TagFilterBlockGridQuery$variables = {
  id: string;
  tag: string;
};
export type TagFilterBlockGridQuery$data = {
  readonly gameList: {
    readonly " $fragmentSpreads": FragmentRefs<"FilterGridGameList_gameList">;
  } | null;
};
export type TagFilterBlockGridQuery = {
  response: TagFilterBlockGridQuery$data;
  variables: TagFilterBlockGridQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tag"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "Variable",
  "name": "tag",
  "variableName": "tag"
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 40
  },
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TagFilterBlockGridQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GameList",
        "kind": "LinkedField",
        "name": "gameList",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Literal",
                "name": "count",
                "value": 40
              },
              (v2/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "FilterGridGameList_gameList"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TagFilterBlockGridQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GameList",
        "kind": "LinkedField",
        "name": "gameList",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gameListId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "internalName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "GameListGames_Connection",
            "kind": "LinkedField",
            "name": "games",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "recommendationId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "recommendationGroupId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GameEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Game",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "launchUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "turnoverContribution",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "marketGameCategory",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "category",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "familyName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "gameId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "provider",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "slug",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Jackpot",
                        "kind": "LinkedField",
                        "name": "jackpot",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "amount",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BetInfo",
                        "kind": "LinkedField",
                        "name": "betInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "minBet",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "maxBet",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "new",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "logo",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "supplier",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "layout",
                            "value": "PORTRAIT"
                          }
                        ],
                        "kind": "ScalarField",
                        "name": "thumbnail",
                        "storageKey": "thumbnail(layout:\"PORTRAIT\")"
                      },
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "filters": [
              "tag"
            ],
            "handle": "connection",
            "key": "PaginatedFilterGridGameList_GameList_games",
            "kind": "LinkedHandle",
            "name": "games"
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5958861fe19f7bb8ca879acf43662486",
    "id": null,
    "metadata": {},
    "name": "TagFilterBlockGridQuery",
    "operationKind": "query",
    "text": "query TagFilterBlockGridQuery(\n  $id: ID!\n  $tag: String!\n) {\n  gameList(id: $id) {\n    ...FilterGridGameList_gameList_2mjSU\n    id\n  }\n}\n\nfragment Decorations_game on Game {\n  ...MonetaryDetails_game\n  ...GameBadge_game\n  ...TurnoverBonus_game\n}\n\nfragment FilterGridGameList_gameList_2mjSU on GameList {\n  ...useTrackGame_gameList\n  games(first: 40, tag: $tag) {\n    ...useTrackGame_games\n    edges {\n      node {\n        ...GameLink_game\n        ...Decorations_game\n        ...GameLogo_game\n        name\n        supplier\n        slug\n        thumbnail(layout: PORTRAIT)\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment GameBadge_game on Game {\n  new\n  familyName\n}\n\nfragment GameLink_game on Game {\n  launchUrl\n  status\n  name\n  ...useGetTurnoverContribution_game\n  ...useTrackGame_game\n}\n\nfragment GameLogo_game on Game {\n  logo\n}\n\nfragment MonetaryDetails_game on Game {\n  jackpot {\n    amount\n  }\n  betInfo {\n    minBet\n    maxBet\n  }\n}\n\nfragment TurnoverBonus_game on Game {\n  ...useGetTurnoverContribution_game\n  familyName\n}\n\nfragment useGetTurnoverContribution_game on Game {\n  turnoverContribution\n  marketGameCategory\n  category\n}\n\nfragment useTrackGame_game on Game {\n  category\n  familyName\n  gameId\n  launchUrl\n  name\n  provider\n  slug\n}\n\nfragment useTrackGame_gameList on GameList {\n  gameListId\n  internalName\n}\n\nfragment useTrackGame_games on GameListGames_Connection {\n  recommendationId\n  recommendationGroupId\n}\n"
  }
};
})();

(node as any).hash = "8cb60eb25ed24a195011b735935a4d8e";

export default node;
