import type { FC } from 'react';
import { useEffect } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { useAsync } from '@pafcloud/react-hook-utils';
import { BettingList, fetchBetOfferGroup } from '@pafcloud/block-components';
import type { BettingListBlock_block$key } from './__generated__/BettingListBlock_block.graphql';

const blockFragment = graphql`
  fragment BettingListBlock_block on BettingListBlock {
    title
    bettingListVariant {
      id
      groupIds
      variant
    }
    kambiOffering {
      baseUrl
      lang
      market
    }
  }
`;

type Props = {
  block: BettingListBlock_block$key;
};

const BettingListBlock: FC<Props> = (props) => {
  const { title, bettingListVariant, kambiOffering } = useFragment(blockFragment, props.block);

  const [state, start] = useAsync({
    onStart: async () => {
      if (kambiOffering == null) {
        throw new Error(`Missing Kambi Offering configuration`);
      }

      const groupId = bettingListVariant.groupIds.join(',');

      const maxNumberEvents = 20;

      if (bettingListVariant.variant === 'live') {
        return fetchBetOfferGroup(kambiOffering, groupId, {
          excludePrematch: true,
          maxNumberEvents,
        });
      }

      if (bettingListVariant.variant === 'startingSoon') {
        return fetchBetOfferGroup(kambiOffering, groupId, {
          excludeLive: true,
          excludeOngoing: true,
          maxNumberEvents,
        });
      }

      return fetchBetOfferGroup(kambiOffering, groupId, {
        maxNumberEvents,
      });
    },
  });

  useEffect(start, [start]);

  if (state.status === 'idle' || state.status === 'pending') {
    // render skeleton?
    return null;
  }

  if (state.status === 'rejected') {
    // Handle?
    return null;
  }

  return (
    <section className="full-width">
      <BettingList title={title} {...state.data}></BettingList>
    </section>
  );
};

export default BettingListBlock;
