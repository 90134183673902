export const createAnimationWithFps = (fps: number) => {
  const fpsDelay = 1000 / fps;
  let running = true;

  const runAnimation = (callback: (nextFrame: () => void) => void) => {
    let start = Date.now();

    const nextFrame = () => {
      if (!running) {
        return;
      }

      const now = Date.now();
      const elapsed = now - start;

      if (elapsed < fpsDelay) {
        window.requestAnimationFrame(nextFrame);
        return;
      }

      start = now;
      callback(() => window.requestAnimationFrame(nextFrame));
    };

    nextFrame();
  };

  const stopAnimation = () => {
    running = false;
  };

  return { runAnimation, stopAnimation };
};
