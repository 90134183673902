import { useEffect, useRef } from 'react';
import { CSSVariables } from '@pafcloud/style';

export const useViewportHeight = () => {
  const throttled = useRef(false);

  useEffect(() => {
    function handleResize() {
      document.documentElement.style.setProperty(CSSVariables.ViewportHeight, `${window.innerHeight}`);
    }

    window.addEventListener('resize', () => {
      if (!throttled.current) {
        throttled.current = true;

        setTimeout(() => {
          handleResize();
          throttled.current = false;
        }, 100);
      }
    });

    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);
};
