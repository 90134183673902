import type { FC } from 'react';
import styled from '@emotion/styled';
import { Icon, Image } from '@pafcloud/base-components';
import type { Icon as IconType } from '@pafcloud/icon';
import type { LinkContainerContentProps } from '../LinkContainer';
import { LinkContainerColors } from '../link-container-colors';

const CardImage = styled(Image)({
  objectFit: 'contain',
  width: 'auto',
  height: 'auto',
  maxWidth: 256,
  maxHeight: 128,
});

const CardIcon = styled.div({
  display: 'grid',
  placeItems: 'center',
  justifySelf: 'center',
  minWidth: 64,
  color: LinkContainerColors.Icon,
  textAlign: 'center',
});

export const LinkContainerFigure: FC<Pick<LinkContainerContentProps, 'figureIcon' | 'image'>> = ({
  figureIcon,
  image,
}) => {
  if (figureIcon) {
    return (
      <CardIcon>
        <Icon name={figureIcon as IconType} size="3rem" />
      </CardIcon>
    );
  }

  if (image?.src) {
    return <CardImage src={image.src} alt={image.altText ?? ''} width={256} height={256} />;
  }

  return null;
};
