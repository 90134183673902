import type { FC } from 'react';
import type { StaticImageData } from 'next/image';
import { useRouter } from 'next/router';
import { intlFormat } from 'date-fns';
import type { LanguageCode } from '@pafcloud/config';
import type { Offering as ko } from '@pafcloud/kambi-api';
import { buildCoupon, formatOutcomes } from '@pafcloud/kambi-api';
import { Image } from '@pafcloud/base-components';
import { useTranslation } from '@pafcloud/i18n';
import { useConfig } from '@pafcloud/contexts';
import {
  BettingCard,
  EventHeader,
  EventLink,
  EventStart,
  EventStartDay,
  EventStartTime,
  EventSubTitle,
  EventTitle,
  LiveContainer,
  LiveIcon,
  LivePeriod,
  MatchScore,
  MatchSetPoints,
  MatchStats,
  OutcomeButton,
  OutcomeLabel,
  OutcomeOdds,
  Outcomes,
  Participant,
  ParticipantName,
} from './bettingListCardStyles';
import { getEventParticipantDetails } from './getEventParticipantDetails';
import { getEventLivePeriod } from './getEventLivePeriod';

type EventStateProps = {
  event: ko.Event;
  liveData?: ko.LiveData;
};

const EventState: FC<EventStateProps> = ({ event, liveData }) => {
  const router = useRouter();
  const { t } = useTranslation('betting');

  if (event.state === 'STARTED') {
    const period = getEventLivePeriod(event.sport, liveData);

    return (
      <LiveContainer>
        <LiveIcon aria-hidden="true">{t('event-state.started')}</LiveIcon>
        {period && <LivePeriod>{period}</LivePeriod>}
      </LiveContainer>
    );
  }

  if (event.state === 'NOT_STARTED') {
    const locale = (router.locale ?? router.defaultLocale) as LanguageCode;

    const eventStartDate = intlFormat(event.start, { month: 'long', day: 'numeric' }, { locale });
    const eventStartTime = intlFormat(event.start, { timeStyle: 'short' }, { locale });

    return (
      <EventStart dateTime={event.start}>
        <EventStartDay>{eventStartDate}</EventStartDay>
        <EventStartTime>{eventStartTime}</EventStartTime>
      </EventStart>
    );
  }

  return null;
};

type BettingListCardProps = {
  betOffer: ko.BetOffer;
  event: ko.Event;
  liveData?: ko.LiveData;
  participantJersey: Map<string, (StaticImageData & { altText: string | null }) | null>;
};

export const BettingListCard: FC<BettingListCardProps> = ({ betOffer, event, liveData, participantJersey }) => {
  const { bettingPageSlug } = useConfig();
  const eventLink = `/${bettingPageSlug}#event/${event.id}`;

  const eventSport = event.path[0].name;
  const eventTitle = event.path[event.path.length - 1].name;

  const { home, away } = getEventParticipantDetails(event, participantJersey, liveData);

  return (
    <BettingCard aria-label={betOffer.betOfferType.name} key={event.id}>
      <EventLink href={eventLink} aria-label={event.name}>
        <EventHeader>
          <div>
            <EventSubTitle>{eventSport}</EventSubTitle>
            <EventTitle>{eventTitle}</EventTitle>
          </div>

          <EventState event={event} liveData={liveData} />
        </EventHeader>
        <Participant key={home.name}>
          {home.jersey && <Image src={home.jersey} width={32} alt={home.jersey.altText ?? ''} loading="lazy" />}

          <ParticipantName>{home.name}</ParticipantName>

          <MatchStats>
            {home.sets.map((set, index) => (
              <MatchSetPoints key={index}>{set == -1 ? 0 : set}</MatchSetPoints>
            ))}

            {home.score && <MatchScore>{home.score}</MatchScore>}
          </MatchStats>
        </Participant>

        <Participant key={away.name}>
          {away.jersey && <Image src={away.jersey} width={32} alt={away.jersey.altText ?? ''} loading="lazy" />}

          <ParticipantName>{away.name}</ParticipantName>

          <MatchStats>
            {away.sets.map((set, index) => (
              <MatchSetPoints key={index}>{set == -1 ? 0 : set}</MatchSetPoints>
            ))}

            {away.score && <MatchScore>{away.score}</MatchScore>}
          </MatchStats>
        </Participant>
      </EventLink>

      <Outcomes>
        {formatOutcomes(betOffer.outcomes).map((outcome) => {
          const coupon = buildCoupon('combination', outcome.id, '', 'append', '');
          const link = `${eventLink}?coupon=${coupon}`;
          return (
            <OutcomeButton
              key={outcome.id}
              aria-label={outcome.label}
              href={link}
              aria-disabled={outcome.status === 'SUSPENDED'}
            >
              <OutcomeLabel>{outcome.label}</OutcomeLabel>
              <OutcomeOdds>{outcome.odds ?? '-'}</OutcomeOdds>
            </OutcomeButton>
          );
        })}
      </Outcomes>
    </BettingCard>
  );
};
