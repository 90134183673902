import type { FC } from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import { ExternalLink } from '@pafcloud/base-components';
import type { PokerGooglePlayLink_data$key } from './__generated__/PokerGooglePlayLink_data.graphql';
import GooglePlayBadgeSVG from './GooglePlayBadge.svg';

const dataFragment = graphql`
  fragment PokerGooglePlayLink_data on PokerLauncher {
    androidApp {
      description
      url
    }
  }
`;

type Props = {
  data: PokerGooglePlayLink_data$key;
};

export const PokerGooglePlayLink: FC<Props> = ({ data }) => {
  const { androidApp } = useFragment(dataFragment, data);

  if (androidApp == null) {
    return null;
  }

  return (
    <ExternalLink aria-label={androidApp.description} href={androidApp.url}>
      <GooglePlayBadgeSVG />
    </ExternalLink>
  );
};
