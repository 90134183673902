import type { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { Breakpoint } from '@pafcloud/style';
import { BlockBackground, HorizontalScroller } from '@pafcloud/base-components';
import { BackLink } from '../back-link';

const BlockContent = styled.section({
  position: 'relative',
  margin: '32px 0',
  lineHeight: '1.5',
  minWidth: 0, // fixes flex/overflow bug.
  maxWidth: '100%',

  [Breakpoint.TabletOrLarger]: {
    justifyContent: 'center',
  },
});

const BackLinkRow = styled.div({
  display: 'flex',
});

const VerticalStack = styled.div({
  display: 'grid',
  gridAutoFlow: 'row',
  gridTemplateColumns: 'minmax(0, 1fr)',
  gap: 16,
});

type BackgroundWrapperProps = {
  hasBackground: boolean;
  children?: ReactNode;
};

const BackgroundWrapper: FC<BackgroundWrapperProps> = ({ hasBackground, children }) => {
  if (hasBackground) {
    return <BlockBackground>{children}</BlockBackground>;
  }

  return children;
};

type BackLinkProps = {
  hasBackLink: boolean | null;
};

type BlockContainerProps = {
  layout: 'VERTICAL' | 'HORIZONTAL';
  hasBackground: boolean;
  children?: ReactNode;
} & BackLinkProps;

// TODO: add prop for using scrolling
export const BlockContainer: FC<BlockContainerProps> = ({ children, layout, hasBackground, hasBackLink }) => {
  return (
    <BlockContent className={layout === 'HORIZONTAL' ? 'full-width' : undefined}>
      <BackgroundWrapper hasBackground={hasBackground}>
        {hasBackLink && (
          <BackLinkRow>
            <BackLink />
          </BackLinkRow>
        )}
        {layout === 'HORIZONTAL' ? (
          <HorizontalScroller>{children}</HorizontalScroller>
        ) : (
          <VerticalStack>{children}</VerticalStack>
        )}
      </BackgroundWrapper>
    </BlockContent>
  );
};
