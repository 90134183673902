import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { HighlightGameList } from '@pafcloud/game-components';
import type { HighlightGameListBlock_block$key } from './__generated__/HighlightGameListBlock_block.graphql';

const blockFragment = graphql`
  fragment HighlightGameListBlock_block on HighlightGameListBlock {
    ...HighlightGameList_block
  }
`;

type Props = {
  block: HighlightGameListBlock_block$key;
};

const HighlightGameListBlock: FC<Props> = (props) => {
  const block = useFragment(blockFragment, props.block);
  return <HighlightGameList block={block} />;
};

export default HighlightGameListBlock;
