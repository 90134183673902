import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import type { Offering as ko } from '@pafcloud/kambi-api';
import type { useEventParticipantJerseysQuery } from './__generated__/useEventParticipantJerseysQuery.graphql';

export const useEventParticipantJerseys = (events: ko.Event[]) => {
  const participantIds = events.flatMap((event) => {
    return (event.participants ?? []).map((p) => {
      return p.participantId.toString();
    });
  });
  const { sportParticipants } = useLazyLoadQuery<useEventParticipantJerseysQuery>(
    graphql`
      query useEventParticipantJerseysQuery($participantIds: [ID!]!) {
        sportParticipants(participantIds: $participantIds) {
          participantId
          jersey {
            url
            height
            width
            altText
          }
        }
      }
    `,
    { participantIds },
  );

  return new Map(
    sportParticipants.map((p) => {
      return [
        p.participantId,
        p.jersey && {
          src: p.jersey.url,
          height: p.jersey.height,
          width: p.jersey.width,
          altText: p.jersey.altText,
        },
      ];
    }),
  );
};
