import type { FC } from 'react';
import styled from '@emotion/styled';
import { ButtonLink } from '@pafcloud/base-components';
import type { BettingOutcome } from './BettingCard';

const OfferButton = styled(ButtonLink)({
  flexGrow: 1,
  padding: '4px 0.75em',
  fontSize: 'max(var(--button-small-font-size), min(4.5vw, var(--button-large-font-size)))',
  lineHeight: 1.2,

  /* iOS versions that doesn't support gap on flex box */
  '@supports not (aspect-ratio: 1)': {
    '& + &': {
      marginLeft: 8,
    },
  },
});

const OutcomeLabel = styled.small({
  display: '-webkit-box',
  overflow: 'hidden',
  minWidth: '1ch',
  opacity: 0.75,

  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  wordBreak: 'break-word',
  textOverflow: 'ellipsis',

  fontSize: '1em',
  fontWeight: 400,
});

const OutcomeOdds = styled.strong({
  fontWeight: 700,
  marginLeft: '0.5em',
});

type Props = {
  outcome: BettingOutcome;
  eventId: number;
  eventLink: string;
  onClick?: () => void;
};

export const BettingOfferButton: FC<Props> = ({ outcome, eventLink, eventId, onClick }) => {
  const getCouponLink = (bettingOutcome: BettingOutcome) => {
    // CouponType | Outcomes | Stakes? | UpdateMode | Source?`;
    return `${eventLink}?coupon=combination|${bettingOutcome.id}||append|Betting Card for ${eventId}` as const;
  };

  return (
    <OfferButton
      href={getCouponLink(outcome)}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.();
      }}
      size="default"
      variant="primary"
      scroll={true}
    >
      <OutcomeLabel>{outcome.label}</OutcomeLabel>
      <OutcomeOdds>{outcome.odds}</OutcomeOdds>
    </OfferButton>
  );
};
