import { graphql } from 'relay-runtime';
import { createUseAsyncMutation } from '@pafcloud/relay-helpers';
import type { usePokerLaunchMutation } from './__generated__/usePokerLaunchMutation.graphql';

const createUsePokerLaunch = createUseAsyncMutation<usePokerLaunchMutation>(graphql`
  mutation usePokerLaunchMutation {
    launchPoker(input: { client: WEB }) {
      __typename
      ... on PokerLaunch {
        popupUrl
      }
      ... on LaunchError {
        error
      }
      ... on LaunchRequiredAgeNotMet {
        ageRequirement
      }
      ... on LaunchCategoryFrozen {
        frozenCategory
        freeze {
          freezeIssuer
          expires
        }
      }
      ... on LaunchBlockedTurnoverCategory {
        blockedCategory
      }
    }
  }
`);

export const usePokerLaunch = createUsePokerLaunch((result) => {
  return result.launchPoker;
});
