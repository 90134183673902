import styled from '@emotion/styled';
import type { FC } from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import { Breakpoint } from '@pafcloud/style';
import { HorizontalScroller } from '@pafcloud/base-components';
import type { PromotionsGrid_promotions$key } from './__generated__/PromotionsGrid_promotions.graphql';
import { PromotionCard } from './PromotionCard';

const promotionsFragment = graphql`
  fragment PromotionsRow_promotions on PromotionCard @relay(plural: true) {
    ...PromotionCard_promotion
  }
`;

const SinglePromotionCard = styled.div({
  display: 'flex',
  justifyContent: 'center',
  paddingInline: 'var(--full-width-margin)',
  width: '100%',

  [Breakpoint.HDScreenOrLarger]: {
    padding: 0,
    width: 'calc(58vw + 16px)',
  },
});

const ScrollablePromotionCards = styled(HorizontalScroller)<{ totalCards: number }>(({ totalCards }) => ({
  '--number-of-items': 1,

  [Breakpoint.TabletOrLarger]: {
    '--number-of-items': 1.5,
  },

  [Breakpoint.LaptopOrLarger]: {
    '--number-of-items': 2,
  },

  [Breakpoint.BigScreenOrLarger]: {
    '--number-of-items': 3,
  },

  [Breakpoint.HDScreenOrLarger]: {
    '--number-of-items': 4,

    justifyContent: totalCards < 3 ? 'center' : undefined,
    '&&& > a': {
      flexGrow: totalCards < 3 ? 0 : undefined,
      flexBasis: totalCards < 3 ? '29vw' : undefined,
    },
  },
}));

type Props = {
  promotions: PromotionsGrid_promotions$key;
};

export const PromotionsRow: FC<Props> = (props) => {
  const promotions = useFragment(promotionsFragment, props.promotions);

  if (promotions.length === 1) {
    return (
      <SinglePromotionCard>
        <PromotionCard promotion={promotions[0]} cardSize="large" />
      </SinglePromotionCard>
    );
  }

  return (
    <ScrollablePromotionCards totalCards={promotions.length}>
      {promotions.map((promotion, index) => (
        <PromotionCard key={index} promotion={promotion} />
      ))}
    </ScrollablePromotionCards>
  );
};
