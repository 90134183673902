/**
 * @generated SignedSource<<12d708f9e05c0f008addfca5816604ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MosaicGameListBlock_block$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MosaicGameList_block">;
  readonly " $fragmentType": "MosaicGameListBlock_block";
};
export type MosaicGameListBlock_block$key = {
  readonly " $data"?: MosaicGameListBlock_block$data;
  readonly " $fragmentSpreads": FragmentRefs<"MosaicGameListBlock_block">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MosaicGameListBlock_block",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MosaicGameList_block"
    }
  ],
  "type": "MosaicGameListBlock",
  "abstractKey": null
};

(node as any).hash = "28783fdf7313026f5e529d549cec2a10";

export default node;
