import type { FC } from 'react';
import styled from '@emotion/styled';
import { ButtonLink } from '@pafcloud/base-components';
import type { LinkContainerContentProps } from '../LinkContainer';

const OpenLink = styled(ButtonLink)({
  minWidth: 120,
});

export const LinkContainerButton: FC<Pick<LinkContainerContentProps, 'link'>> = ({ link }) => {
  if (link == null) {
    return null;
  }

  return (
    link.href && (
      <OpenLink href={link.href} size="default" variant="secondary" aria-label={link.ariaLabel ?? undefined}>
        {link.linkText}
      </OpenLink>
    )
  );
};
