import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { Colors as Colors_default } from './colors.default';
import { Colors as Colors_dreams } from './colors.dreams';
import { Colors as Colors_momentum } from './colors.momentum';

export const BettingColors = (() => {
  if ($buildEnv.theme === 'dreams') {
    return Colors_dreams;
  }
  if ($buildEnv.theme === 'momentum') {
    return Colors_momentum;
  }

  return Colors_default;
})();
