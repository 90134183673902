import { useEffect } from 'react';
import type { FC } from 'react';
import styled from '@emotion/styled';
import { GameCard, CardBody, CardInfoSausage, LoadingIndicator, ButtonLink } from '@pafcloud/base-components';
import { Breakpoint, Color } from '@pafcloud/style';
import { useFormatDate, useHandler } from '@pafcloud/react-hook-utils';
import { dispatchTrackingEvent } from '@pafcloud/tracking';
import { useConfig } from '@pafcloud/contexts';
import { BettingOfferButton } from './BettingOfferButton';
import { BettingTitle } from './BettingTitle';

export const BettingSection = styled.section({
  '--page-margin': 'var(--full-width-margin)',

  paddingRight: 'var(--page-margin)',
  paddingLeft: 'var(--page-margin)',
  overflow: 'hidden',

  display: 'grid',
  gridAutoFlow: 'row',
});

export const ShowMoreButton = styled(ButtonLink)({
  margin: '32px 0 16px',
  minWidth: 176,
  justifySelf: 'center',
});

const Betting = styled(GameCard)<{ onClick?: () => void }>({
  overflow: 'hidden',
  marginBottom: 32,

  [Breakpoint.LaptopOrLarger]: {
    minHeight: 400,
  },
});

const BettingCardLoadingIndicator = styled(LoadingIndicator)({
  gridColumn: 'span 2',
  minHeight: 300,
});

const Time = styled.time({
  color: Color.TextColors.MutedText,
});

const ButtonsWrapper = styled.div({
  display: 'flex',
  alignContent: 'center',
  gap: 8,
  marginTop: 32,
});

export type BettingEvent = {
  id: number;
  label: string;
  homeName: string;
  awayName: string;
  start: string;
  outcomes: BettingOutcome[];
  sport: string;
  paths: string[];
  trackingItem: string;
};

export type BettingOutcome = {
  id: number;
  label: string;
  odds: number | string | undefined;
};

type Props = {
  event?: BettingEvent;
  thumbnail?: string;
  loading: boolean;
};

export const BettingCard: FC<Props> = ({ event, thumbnail, loading }) => {
  const formatDate = useFormatDate();
  const { bettingPageSlug } = useConfig();

  const trackBetting = useHandler((trackingEvent: 'hero-click' | 'hero-impression', trackingItem: string) => {
    dispatchTrackingEvent(trackingEvent, {
      hero: {
        contentName: 'Widget: Betting widget',
        itemName: trackingItem,
        pagePosition: 'Betting block',
        type: 'hero',
        index: 0,
      },
    });
  });

  useEffect(() => {
    if (event == null) {
      return;
    }
    trackBetting('hero-impression', event.trackingItem);
  }, [trackBetting, event]);

  if (loading) {
    return (
      <Betting>
        <BettingCardLoadingIndicator center size={32} />
      </Betting>
    );
  }

  if (event == null) {
    return null;
  }

  const eventLink = `/${bettingPageSlug}#event/${event.id}`;

  return (
    <Betting
      backgroundUrl={thumbnail}
      href={eventLink}
      scroll
      linkText={eventLink}
      onClick={() => trackBetting('hero-click', event.trackingItem)}
    >
      <CardBody onClick={() => trackBetting('hero-click', event.trackingItem)}>
        <CardInfoSausage text={event.label} />
        <BettingTitle homeName={event.homeName} awayName={event.awayName} />
        <Time>{formatDate(event.start)}</Time>
        <ButtonsWrapper>
          {event.outcomes.map((outcome) => (
            <BettingOfferButton
              key={outcome.id}
              outcome={outcome}
              eventLink={eventLink}
              eventId={event.id}
              onClick={() => trackBetting('hero-click', event.trackingItem)}
            />
          ))}
        </ButtonsWrapper>
      </CardBody>
    </Betting>
  );
};
