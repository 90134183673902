import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import styled from '@emotion/styled';
import { Heading, PrimaryHeading } from '@pafcloud/base-components';
import { useTranslation } from '@pafcloud/i18n';
import { FontHeadingSize } from '@pafcloud/style';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { InformationContainer } from '../../information-container';
import { BlockContainer } from '../../block-container';
import type { LoyaltyProgramLevelDetails_block$key } from './__generated__/LoyaltyProgramLevelDetails_block.graphql';

const loyaltyProgramLevelDetailsFragment = graphql`
  fragment LoyaltyProgramLevelDetails_block on LoyaltyProgramDetailsBlock {
    loyaltyLevels {
      ...LoyaltyProgramLevelImages_image @relay(mask: false)
    }
  }
`;

const DetailsHeading = styled(Heading)(PrimaryHeading, {
  display: 'inline-block',
  marginBottom: 0,
  fontSize: FontHeadingSize.Big,
  isolation: 'isolate',
});

// God help me for this hack, I'm sorry for my sins.
const Section = styled(BlockContainer)({});

type Props = {
  block: LoyaltyProgramLevelDetails_block$key;
};

const shouldShowBackground = $buildEnv.theme === 'momentum' || $buildEnv.theme === 'momentum-neon';

export const LoyaltyProgramLevelDetails: FC<Props> = ({ block }) => {
  const { t } = useTranslation('loyalty-program');
  const { loyaltyLevels: levels } = useFragment(loyaltyProgramLevelDetailsFragment, block);

  return (
    <Section layout="VERTICAL" hasBackground={shouldShowBackground} hasBackLink={false}>
      <header>
        <DetailsHeading css={PrimaryHeading}>{t('page.level-details')}</DetailsHeading>
      </header>
      {levels.map((level) => {
        const image = level.badge?.url
          ? { src: level.badge.url, width: level.badge.width, height: level.badge.height, altText: level.title }
          : undefined;

        return (
          <InformationContainer
            key={level.title}
            layout="HORIZONTAL"
            heading={level.title}
            description={level.description}
            image={image}
          />
        );
      })}
    </Section>
  );
};
