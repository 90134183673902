/**
 * @generated SignedSource<<59f446f60dfe45b04a255f3b993b0792>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type BlockLayout = "HORIZONTAL" | "VERTICAL";
import { FragmentRefs } from "relay-runtime";
export type ContainerBlock_block$data = {
  readonly blockLayout: BlockLayout;
  readonly blocks: ReadonlyArray<{
    readonly __typename: string;
    readonly " $fragmentSpreads": FragmentRefs<"AccordionBlock_block" | "BonusTermsBlock_block" | "GameShortcutBlock_block" | "LinkContainerBlock_block" | "RichTextBlock_block" | "TableBlock_block">;
  } | null>;
  readonly hasBackLink: boolean | null;
  readonly hasBackground: boolean;
  readonly " $fragmentType": "ContainerBlock_block";
};
export type ContainerBlock_block$key = {
  readonly " $data"?: ContainerBlock_block$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContainerBlock_block">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContainerBlock_block",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "blocks",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AccordionBlock_block"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BonusTermsBlock_block"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GameShortcutBlock_block"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "LinkContainerBlock_block"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RichTextBlock_block"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TableBlock_block"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "blockLayout",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasBackground",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasBackLink",
      "storageKey": null
    }
  ],
  "type": "ContainerBlock",
  "abstractKey": null
};

(node as any).hash = "6962ec9dfce693e3a2c061a767121464";

export default node;
