import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { TabGroupLinks } from '@pafcloud/block-components';
import type { TabGroupLinksBlock_block$key } from './__generated__/TabGroupLinksBlock_block.graphql';

const blockFragment = graphql`
  fragment TabGroupLinksBlock_block on TabGroupLinksBlock {
    ...TabGroupLinks_links
  }
`;

type TabGroupLinksBlockProps = {
  block: TabGroupLinksBlock_block$key;
};

const TabGroupLinksBlock: FC<TabGroupLinksBlockProps> = (props) => {
  const block = useFragment(blockFragment, props.block);

  return <TabGroupLinks links={block} />;
};

export default TabGroupLinksBlock;
