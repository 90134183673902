import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { Colors as Colors_default } from './colors.default';
import { Colors as Colors_quirky } from './colors.quirky';
import { Colors as Colors_dreams } from './colors.dreams';

export const LinkContainerColors = (() => {
  if ($buildEnv.theme === 'quirky') {
    return Colors_quirky;
  }
  if ($buildEnv.theme === 'dreams') {
    return Colors_dreams;
  }

  return Colors_default;
})();
