import type { FC } from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import { ExternalLink } from '@pafcloud/base-components';
import type { PokerDownloadButton_data$key } from './__generated__/PokerDownloadButton_data.graphql';
import WindowsBadgeSVG from './WindowsBadge.svg';

const dataFragment = graphql`
  fragment PokerDownloadButton_data on PokerLauncher {
    downloadClientButtonDescription
    downloadClientUrl
  }
`;

type Props = {
  data: PokerDownloadButton_data$key;
};

export const PokerDownloadButton: FC<Props> = ({ data }) => {
  const { downloadClientButtonDescription, downloadClientUrl } = useFragment(dataFragment, data);

  if (downloadClientUrl == null) {
    return null;
  }

  return (
    <ExternalLink aria-label={downloadClientButtonDescription ?? ''} href={downloadClientUrl}>
      <WindowsBadgeSVG />
    </ExternalLink>
  );
};
