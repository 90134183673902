import { graphql } from 'relay-runtime';
import { createUseMutation } from '@pafcloud/relay-helpers';
import type { useFreezeLevelMutation } from './__generated__/useFreezeLevelMutation.graphql';

const createUseFreezeLevel = createUseMutation<useFreezeLevelMutation>(graphql`
  mutation useFreezeLevelMutation {
    freezeLevel {
      player {
        loyaltyProgram {
          currentLevelFrozen
          remainingFreezes
        }
      }
    }
  }
`);

export const useFreezeLevel = createUseFreezeLevel((result) => {
  return result.freezeLevel.player;
});
