/**
 * @generated SignedSource<<5ce9c8e9b48fdc91d07dd141366aabde>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PromotionsBlock_block$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Promotions_block">;
  readonly " $fragmentType": "PromotionsBlock_block";
};
export type PromotionsBlock_block$key = {
  readonly " $data"?: PromotionsBlock_block$data;
  readonly " $fragmentSpreads": FragmentRefs<"PromotionsBlock_block">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PromotionsBlock_block",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Promotions_block"
    }
  ],
  "type": "PromotionsBlock",
  "abstractKey": null
};

(node as any).hash = "01650a207d6045b59da0f03f94eeb8b4";

export default node;
