/**
 * @generated SignedSource<<74877cde7b55b05fb6889978a18abed2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LoyaltyProgramProgressBlockQuery$variables = Record<PropertyKey, never>;
export type LoyaltyProgramProgressBlockQuery$data = {
  readonly player: {
    readonly age: number | null;
    readonly " $fragmentSpreads": FragmentRefs<"LoyaltyProgramProgress_player">;
  } | null;
};
export type LoyaltyProgramProgressBlockQuery = {
  response: LoyaltyProgramProgressBlockQuery$data;
  variables: LoyaltyProgramProgressBlockQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "age",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LoyaltyProgramProgressBlockQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Player",
        "kind": "LinkedField",
        "name": "player",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "LoyaltyProgramProgress_player"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LoyaltyProgramProgressBlockQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Player",
        "kind": "LinkedField",
        "name": "player",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "LoyaltyProgramPlayer",
            "kind": "LinkedField",
            "name": "loyaltyProgram",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "levelPeriodEndsAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "estimatedCashback",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rewardPeriodEndsAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "currentLevelFrozen",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "remainingFreezes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "level",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "monthlyInitialLevel",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "monthlyPoints",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "91e2fcc70bf698e91b30c8f1f9dfb837",
    "id": null,
    "metadata": {},
    "name": "LoyaltyProgramProgressBlockQuery",
    "operationKind": "query",
    "text": "query LoyaltyProgramProgressBlockQuery {\n  player {\n    age\n    ...LoyaltyProgramProgress_player\n    id\n  }\n}\n\nfragment LoyaltyProgramFreeze_freezes on LoyaltyProgramPlayer {\n  currentLevelFrozen\n  remainingFreezes\n  levelPeriodEndsAt\n}\n\nfragment LoyaltyProgramPayout_payout on LoyaltyProgramPlayer {\n  estimatedCashback\n  rewardPeriodEndsAt\n}\n\nfragment LoyaltyProgramProgress_player on Player {\n  loyaltyProgram {\n    levelPeriodEndsAt\n    ...LoyaltyProgramPayout_payout\n    ...LoyaltyProgramFreeze_freezes\n  }\n  ...useCalculateProgress_player\n}\n\nfragment useCalculateProgress_player on Player {\n  loyaltyProgram {\n    level\n    monthlyInitialLevel\n    monthlyPoints\n  }\n}\n"
  }
};
})();

(node as any).hash = "8d3345749a6e4a66c10fbd69495e9582";

export default node;
