import type { FC, ReactNode } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { LinkContainer } from '@pafcloud/block-components';
import type { LinkContainerBlock_block$key } from './__generated__/LinkContainerBlock_block.graphql';

const blockFragment = graphql`
  fragment LinkContainerBlock_block on LinkContainerBlock {
    heading
    blockLayout
    description
    figure {
      src
      altText
      icon
    }
    href
    linkText
    ariaLabel
  }
`;

type Props = {
  block: LinkContainerBlock_block$key;
  blockRenderer?: ReactNode;
};

const LinkContainerBlock: FC<Props> = (props) => {
  const block = useFragment(blockFragment, props.block);

  const { heading, description, figure, href, linkText, ariaLabel, blockLayout } = block;
  const image = figure.src ? { src: figure.src, altText: figure.altText } : undefined;
  const icon = figure.icon;

  return (
    <LinkContainer
      heading={heading}
      description={description}
      figureIcon={icon}
      layout={blockLayout}
      image={image}
      link={{ href, linkText, ariaLabel }}
    />
  );
};

export default LinkContainerBlock;
