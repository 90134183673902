import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { Colors as Colors_classic } from './colors.classic';
import { Colors as Colors_quirky } from './colors.quirky';
import { Colors as Colors_dreams } from './colors.dreams';

export const GameCountColors = (() => {
  if ($buildEnv.theme === 'quirky') {
    return Colors_quirky;
  }

  if ($buildEnv.theme === 'dreams') {
    return Colors_dreams;
  }

  console.warn('Colors not specified for game-count-colors!');
  return Colors_classic;
})();
