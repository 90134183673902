import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import type { HeroType } from '../../Hero';
import { heroBackgroundFallback as heroBackgroundFallback_classic_default } from './heroBackgroundFallback.default';
import { heroBackgroundFallback as heroBackgroundFallback_classic } from './heroBackgroundFallback.classic';
import { heroBackgroundFallback as heroBackgroundFallback_classic_red } from './heroBackgroundFallback.classic-red';
import { heroBackgroundFallback as heroBackgroundFallback_golden } from './heroBackgroundFallback.golden';
import { heroBackgroundFallback as heroBackgroundFallback_quirky } from './heroBackgroundFallback.quirky';
import { heroBackgroundFallback as heroBackgroundFallback_clean_blue } from './heroBackgroundFallback.clean-blue';
import { heroBackgroundFallback as heroBackgroundFallback_clean } from './heroBackgroundFallback.clean';
import { heroBackgroundFallback as heroBackgroundFallback_dreams } from './heroBackgroundFallback.dreams';
import { heroBackgroundFallback as heroBackgroundFallback_momentum } from './heroBackgroundFallback.momentum';

export const heroBackgroundFallback = (heroType: HeroType) =>
  (() => {
    if ($buildEnv.theme === 'clean') {
      return heroBackgroundFallback_clean;
    }
    if ($buildEnv.theme === 'clean-blue') {
      return heroBackgroundFallback_clean_blue;
    }
    if ($buildEnv.theme === 'quirky') {
      return heroBackgroundFallback_quirky;
    }
    if ($buildEnv.theme === 'quirky-purple') {
      return heroBackgroundFallback_quirky;
    }
    if ($buildEnv.theme === 'golden') {
      return heroBackgroundFallback_golden;
    }
    if ($buildEnv.theme === 'goldenV2') {
      return heroBackgroundFallback_golden;
    }
    if ($buildEnv.theme === 'classic') {
      if ($buildEnv.site === 'paf.se' || $buildEnv.site === 'paf.es') {
        return heroBackgroundFallback_classic(heroType);
      }
      return heroBackgroundFallback_classic_default;
    }
    if ($buildEnv.theme === 'classic-red') {
      return heroBackgroundFallback_classic_red(heroType);
    }
    if ($buildEnv.theme === 'dreams') {
      return heroBackgroundFallback_dreams(heroType);
    }
    if ($buildEnv.theme === 'momentum') {
      return heroBackgroundFallback_momentum(heroType);
    }
    if ($buildEnv.theme === 'momentum-neon') {
      return heroBackgroundFallback_momentum(heroType);
    }
    return heroBackgroundFallback_classic_default;
  })();
