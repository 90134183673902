/**
 * @generated SignedSource<<2403b9c4e4560fdf29c3e0cc0347d159>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LoyaltyProgramFreeze_freezes$data = {
  readonly currentLevelFrozen: boolean;
  readonly levelPeriodEndsAt: string;
  readonly remainingFreezes: number;
  readonly " $fragmentType": "LoyaltyProgramFreeze_freezes";
};
export type LoyaltyProgramFreeze_freezes$key = {
  readonly " $data"?: LoyaltyProgramFreeze_freezes$data;
  readonly " $fragmentSpreads": FragmentRefs<"LoyaltyProgramFreeze_freezes">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LoyaltyProgramFreeze_freezes",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentLevelFrozen",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remainingFreezes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "levelPeriodEndsAt",
      "storageKey": null
    }
  ],
  "type": "LoyaltyProgramPlayer",
  "abstractKey": null
};

(node as any).hash = "e292d327c172a88f6d89dda339c67b1f";

export default node;
