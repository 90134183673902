import type { FC } from 'react';
import styled from '@emotion/styled';
import { Breakpoint, LayoutProps } from '@pafcloud/style';
import { useMedia } from '@pafcloud/react-hook-utils';

const IframeWrapper = styled.div({
  margin: 0,
  width: '100%',
  position: 'relative',
  aspectRatio: '16/9',
  maxHeight: `calc(90vh - ${LayoutProps.GamePauseHeight + LayoutProps.HeaderHeight}px)`,

  // iOS 14 does not support aspect-ratio
  '@supports not (aspect-ratio: 16/9)': {
    '&::before': {
      content: '""',
      width: '1px',
      marginLeft: '-1px',
      float: 'left',
      height: 0,
      paddingTop: '56.25%',
    },
    '&::after': {
      content: '""',
      display: 'table',
      clear: 'both',
    },
  },
});

const Iframe = styled.iframe({
  border: 'none',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
});

type Props = {
  src: string;
  allow?: 'fullscreen';
};

export const SizedIframe: FC<Props> = ({ src, allow }) => {
  const isMobile = useMedia(Breakpoint.Phone);

  return (
    <IframeWrapper className={isMobile ? 'full-width' : undefined}>
      <Iframe src={src} allow={allow} />
    </IframeWrapper>
  );
};
