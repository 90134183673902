/**
 * @generated SignedSource<<e4c916325085c3294bd6ac03fdf0219d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TabGroupLinks_links$data = {
  readonly links: ReadonlyArray<{
    readonly __typename: "TabGroupBlockPageType";
    readonly slug: string;
    readonly title: string;
  } | {
    readonly __typename: "TabGroupDropdown";
    readonly dropdownTitle: string;
    readonly pages: ReadonlyArray<{
      readonly slug: string;
      readonly title: string;
    }>;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  }>;
  readonly " $fragmentType": "TabGroupLinks_links";
};
export type TabGroupLinks_links$key = {
  readonly " $data"?: TabGroupLinks_links$data;
  readonly " $fragmentSpreads": FragmentRefs<"TabGroupLinks_links">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "slug",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "title",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TabGroupLinks_links",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "links",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "TabGroupBlockPageType",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dropdownTitle",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TabGroupBlockPageType",
              "kind": "LinkedField",
              "name": "pages",
              "plural": true,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "type": "TabGroupDropdown",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TabGroupLinksBlock",
  "abstractKey": null
};
})();

(node as any).hash = "749b8e8413e2529a312c2611c587851b";

export default node;
