/**
 * @generated SignedSource<<235d64b04daac3b7a3b529dcbf3ce521>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PokerDownloadButton_data$data = {
  readonly downloadClientButtonDescription: string | null;
  readonly downloadClientUrl: string | null;
  readonly " $fragmentType": "PokerDownloadButton_data";
};
export type PokerDownloadButton_data$key = {
  readonly " $data"?: PokerDownloadButton_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"PokerDownloadButton_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PokerDownloadButton_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "downloadClientButtonDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "downloadClientUrl",
      "storageKey": null
    }
  ],
  "type": "PokerLauncher",
  "abstractKey": null
};

(node as any).hash = "ad5defc167465cf22881eb4255286873";

export default node;
