import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { removeNullValues } from '@pafcloud/collection-utils';
import { BackgroundContainer } from '@pafcloud/block-components';
import { BlockRenderer } from '../BlockRenderer';
import type { BackgroundContainerBlock_block$key } from './__generated__/BackgroundContainerBlock_block.graphql';

const blockFragment = graphql`
  fragment BackgroundContainerBlock_block on BackgroundContainerBlock {
    blocks {
      ...BlockRenderer_blocks
    }
    backgroundContainerImage
    position
    disableOpacity
    hasGradient
    colors
  }
`;

type Props = {
  block: BackgroundContainerBlock_block$key;
};

const BackgroundContainerBlock: FC<Props> = (props) => {
  const block = useFragment(blockFragment, props.block);

  return (
    <BackgroundContainer {...block}>
      <BlockRenderer blocks={removeNullValues(block.blocks)} />
    </BackgroundContainer>
  );
};

export default BackgroundContainerBlock;
