import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { LoyaltyProgramLevelDetails } from '@pafcloud/block-components';
import type { LoyaltyProgramDetailsBlock_block$key } from './__generated__/LoyaltyProgramDetailsBlock_block.graphql';

const blockFragment = graphql`
  fragment LoyaltyProgramDetailsBlock_block on LoyaltyProgramDetailsBlock {
    ...LoyaltyProgramLevelDetails_block
  }
`;

type Props = {
  block: LoyaltyProgramDetailsBlock_block$key;
};

const LoyaltyProgramDetailsBlock: FC<Props> = (props) => {
  const block = useFragment(blockFragment, props.block);

  if (block == null) {
    return null;
  }

  return <LoyaltyProgramLevelDetails block={block} />;
};

export default LoyaltyProgramDetailsBlock;
