import type { StaticImageData } from 'next/image';
import type { Offering as ko } from '@pafcloud/kambi-api';

export const getEventParticipantDetails = (
  event: ko.Event,
  participantJersey: Map<string, (StaticImageData & { altText: string | null }) | null>,
  eventLiveData?: ko.LiveData,
) => {
  const participants = event.participants ?? [];
  const homeJersey = participantJersey.get(`${participants.find((p) => p.home)?.participantId.toString()}`);
  const awayJersey = participantJersey.get(`${participants.find((p) => !p.home)?.participantId.toString()}`);
  const showJerseys = homeJersey != null && awayJersey != null;

  const home = {
    jersey: showJerseys ? homeJersey : null,
    name: event.homeName,
    score: eventLiveData?.score?.home,
    sets: eventLiveData?.statistics?.sets?.home ?? [],
  };

  const away = {
    jersey: showJerseys ? awayJersey : null,
    name: event.awayName,
    score: eventLiveData?.score?.away,
    sets: eventLiveData?.statistics?.sets?.away ?? [],
  };

  return { home, away, showJerseys };
};
