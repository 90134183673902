/**
 * @generated SignedSource<<c295620cad24f194c4d6f972a994df6a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MultiplayerBingoListBlock_block$data = {
  readonly list: {
    readonly " $fragmentSpreads": FragmentRefs<"MultiplayerBingoList_bingoGameList">;
  };
  readonly title: string | null;
  readonly " $fragmentType": "MultiplayerBingoListBlock_block";
};
export type MultiplayerBingoListBlock_block$key = {
  readonly " $data"?: MultiplayerBingoListBlock_block$data;
  readonly " $fragmentSpreads": FragmentRefs<"MultiplayerBingoListBlock_block">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MultiplayerBingoListBlock_block",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MultiplayerBingoList",
      "kind": "LinkedField",
      "name": "list",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MultiplayerBingoList_bingoGameList"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MultiplayerBingoListBlock",
  "abstractKey": null
};

(node as any).hash = "57907ec06c2372dc705d1275b68185e2";

export default node;
