import type { FC } from 'react';
import { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { useRouter } from 'next/router';
import { removeNullValues, isType } from '@pafcloud/collection-utils';
import { TagFilter } from '@pafcloud/block-components';
import { FilterGridGameList } from '@pafcloud/game-components';
import { SafeQueryRenderer } from '@pafcloud/relay-helpers';
import type { GraphqlTypes as gt } from '@pafcloud/graphql-schema/types';
import { BlockRenderer } from '../BlockRenderer';
import type { TagFilterBlock_block$key } from './__generated__/TagFilterBlock_block.graphql';
import type { TagFilterBlockGridQuery } from './__generated__/TagFilterBlockGridQuery.graphql';

const blockFragment = graphql`
  fragment TagFilterBlock_block on TagFilterBlock {
    gameListId
    tags {
      __typename
      ... on Tag {
        slug
        title
        gameCount
      }
      ... on TagGroup {
        title
        tags {
          slug
          title
          gameCount
        }
      }
    }
    showAllButtonText
    blocks {
      ...BlockRenderer_blocks
    }
    ...FilterGridGameList_trackable
  }
`;

const tagFilterGridQuery = graphql`
  query TagFilterBlockGridQuery($id: ID!, $tag: String!) {
    gameList(id: $id) {
      ...FilterGridGameList_gameList @arguments(tag: $tag, count: 40)
    }
  }
`;

type Props = {
  block: TagFilterBlock_block$key;
};

const TagFilterBlock: FC<Props> = (props) => {
  const block = useFragment(blockFragment, props.block);
  const { gameListId, tags, showAllButtonText, blocks } = block;
  const { query } = useRouter();

  const selectedTag = useMemo<gt.Tag | null>(() => {
    if (!query.tag) {
      return null;
    }

    let foundTag: Partial<gt.Tag> | null = null;

    tags.forEach((tag) => {
      if (isType(tag, 'TagGroup')) {
        tag.tags.forEach((t) => {
          if (t.slug === query.tag) {
            foundTag = t;
          }
        });
      } else if (isType(tag, 'Tag')) {
        if (tag.slug === query.tag) {
          foundTag = tag;
        }
      }
    });

    return foundTag;
  }, [query.tag, tags]);

  return (
    <>
      <TagFilter
        tags={tags as (gt.Tag | gt.TagGroup)[]}
        showAllButtonText={showAllButtonText}
        selectedTag={selectedTag}
      />

      {(() => {
        if (selectedTag) {
          return (
            <SafeQueryRenderer<TagFilterBlockGridQuery>
              query={tagFilterGridQuery}
              variables={{ id: gameListId, tag: selectedTag.slug }}
              render={(data) => {
                if (data.props.gameList == null) {
                  return null;
                }
                return <FilterGridGameList title={selectedTag.title} gameList={data.props.gameList} tracking={block} />;
              }}
            />
          );
        }

        return <BlockRenderer blocks={removeNullValues(blocks)} />;
      })()}
    </>
  );
};

export default TagFilterBlock;
