import type { FC } from 'react';
import styled from '@emotion/styled';
import dynamic from 'next/dynamic';
import { useTranslation } from '@pafcloud/i18n';
import { Heading, MarkdownParser } from '@pafcloud/base-components';
import { Color, Font, FontHeadingSize, FontTextSize } from '@pafcloud/style';
import { AccessGuardLoadingIndicator } from '../access-guard/guards/AccessGuardLoadingIndicator';
import type { BettingClientErrorEvent } from './bettingErrorEvents';

const GameFreezeGuard = dynamic(() => import('../access-guard/guards/game-freeze/GameFreezeGuard'), {
  loading: () => <AccessGuardLoadingIndicator />,
});
const AgeLimitGuard = dynamic(() => import('../access-guard/guards/age-limit-guard/AgeLimitGuard'), {
  loading: () => <AccessGuardLoadingIndicator />,
});
const TurnoverGuard = dynamic(() => import('../access-guard/guards/turnover-guard/TurnoverGuard'), {
  loading: () => <AccessGuardLoadingIndicator />,
});

const Header = styled(Heading)({
  fontSize: FontHeadingSize.Small,
  fontFamily: Font.Heading,
});

const Paragraph = styled(MarkdownParser)({
  fontSize: FontTextSize.Huge,
  color: Color.TextColors.BodyText,
});

type Props = {
  error: BettingClientErrorEvent;
};

export const BettingClientError: FC<Props> = ({ error }) => {
  const { t } = useTranslation('common');

  if (error.errorCode === 'categoryFrozen') {
    const { expires, issuer } = error.freeze;
    return <GameFreezeGuard category={error.frozenCategory} freeze={{ expires, freezeIssuer: issuer }} />;
  }

  if (error.errorCode === 'requiredAgeNotMet') {
    return <AgeLimitGuard requiredAge={error.ageRequirement} />;
  }

  if (error.errorCode === 'blockedTurnoverCategory') {
    return <TurnoverGuard category={error.blockedCategory} bettingProvider={error.provider} />;
  }

  return (
    <div>
      <Header>{t('error.header')}</Header>
      <Paragraph>{t('error.text')}</Paragraph>
    </div>
  );
};
