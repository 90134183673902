import type { SyntheticEvent } from 'react';
import { useRef, useState } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { dispatchTrackingEvent } from '@pafcloud/tracking';
import type { KambiTrackingEvent, TrackingEvent } from '@pafcloud/tracking/src/events';
import { useTranslation } from '@pafcloud/i18n';
import { useIsLoggedIn } from '@pafcloud/contexts';
import { useFlowRouter } from '@pafcloud/flow-router';
import { useViewportHeight } from '../header/utils/useViewportHeight';
import { AccessGuardLoadingIndicator } from '../access-guard/guards/AccessGuardLoadingIndicator';
import { useBettingKeepAlive } from './useBettingKeepAlive';
import type { BettingClientErrorEvent } from './bettingErrorEvents';
import { BettingClientError } from './BettingClientError';
import { BettingWrapper, BettingIframe, BettingIframeSkeleton } from './BettingWrapper';

const ProviderMaintenanceGuard = dynamic(
  () => import('../access-guard/guards/provider-maintenance-guard/ProviderMaintenanceGuard'),
  { loading: () => <AccessGuardLoadingIndicator /> },
);

type KambiMaintenanceEvent = {
  name: 'maintenance';
  state: {
    isMaintenance: boolean;
    startingAt: string | null; // (YYYY-MM-DDTHH:SS) in UTC time
    endingAt: string | null; // (YYYY-MM-DDTHH:SS) in UTC time
  };
};
type KambiLoginEvent = { name: 'loginRequested' };
type KambiDepositEvent = { name: 'depositRequested' };
type KambiRouteEvent = { name: 'pageRendered'; hash: string };
type KambiDataLayerPushedEvent = {
  name: 'dataLayerPushed';
  data: { event: KambiTrackingEvent; 'gtm.uniqueEventId'?: number } & TrackingEvent[KambiTrackingEvent];
};

type KambiEvent =
  | KambiMaintenanceEvent
  | KambiLoginEvent
  | KambiDepositEvent
  | KambiRouteEvent
  | KambiDataLayerPushedEvent
  | BettingClientErrorEvent;

export const IframedKambiBettingClient = () => {
  const { t } = useTranslation('betting');
  const router = useRouter();
  const isLoggedIn = useIsLoggedIn();
  const flowRouter = useFlowRouter();
  const { keepAlive } = useBettingKeepAlive();
  const [error, setError] = useState<BettingClientErrorEvent>();
  const [maintenance, setMaintenance] = useState(false);
  const [loading, setLoading] = useState(true);
  useViewportHeight();

  const { current: initialHash } = useRef(window.location.hash);

  const onLoad = (e: SyntheticEvent<HTMLIFrameElement, Event>) => {
    const iframeWindow = e.currentTarget.contentWindow;
    if (iframeWindow == null) {
      return;
    }

    const onMessage = async (event: MessageEvent<KambiEvent>) => {
      if (event.origin !== location.origin) {
        return;
      }

      const eventData = event.data;

      keepAlive();

      if (eventData.name === 'maintenance') {
        setMaintenance(eventData.state.isMaintenance);
      }

      if (eventData.name === 'loginRequested') {
        await flowRouter.openFlow('login');
      }

      if (eventData.name === 'depositRequested') {
        await flowRouter.openFlow('deposit');
      }

      if (eventData.name === 'pageRendered') {
        if (loading) {
          setLoading(false);
        }

        window.scrollTo({ left: 0, top: 0 });
        // Keep iframe and window routes synced

        router.replace({ hash: eventData.hash }, undefined, { shallow: true }).catch(() => {
          // Workaround for https://github.com/vercel/next.js/issues/37362
        });
      }

      if (eventData.name === 'dataLayerPushed') {
        dispatchTrackingEvent(eventData.data.event, { kambi: eventData.data.kambi });
      }

      if (eventData.name === 'error') {
        if (eventData.errorCode === 'blockedTurnoverCategory') {
          eventData.provider = 'KAMBI';
        }
        setError(eventData);
      }
    };

    iframeWindow.addEventListener('message', onMessage);
  };

  if (error != null) {
    return <BettingClientError error={error} />;
  }

  if (maintenance) {
    return <ProviderMaintenanceGuard />;
  }

  return (
    <BettingWrapper className="full-width">
      {loading && <BettingIframeSkeleton />}
      <BettingIframe
        title={t('page-title.sports-betting')}
        onLoad={onLoad}
        src={`/static/betting-clients/kambi/kambi-client.html?language=${router.locale}&outcomeID=true${initialHash}`}
        key={`${router.locale}-${isLoggedIn}`}
      />
    </BettingWrapper>
  );
};
