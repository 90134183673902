/**
 * @generated SignedSource<<4bba1546219cd0f48f1416e3bde3ec0e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BettingProvider = "COLOSSUS_BETS" | "KAMBI" | "SCOUT_GAMING";
export type GamingFreezeIssuer = "HAMPI" | "PLAYER" | "SOURCE_OF_FUNDS" | "SOURCE_OF_WEALTH";
export type LaunchBettingInput = {
  provider: BettingProvider;
};
export type useLaunchBettingMutation$variables = {
  input: LaunchBettingInput;
};
export type useLaunchBettingMutation$data = {
  readonly launchBetting: {
    readonly __typename: "ColossusBetsBettingLaunch";
    readonly launchUrl: string;
  } | {
    readonly __typename: "LaunchBlockedTurnoverCategory";
    readonly blockedCategory: string;
  } | {
    readonly __typename: "LaunchCategoryFrozen";
    readonly freeze: {
      readonly expires: string | null;
      readonly freezeIssuer: GamingFreezeIssuer;
    };
    readonly frozenCategory: string;
  } | {
    readonly __typename: "LaunchRequiredAgeNotMet";
    readonly ageRequirement: number;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type useLaunchBettingMutation = {
  response: useLaunchBettingMutation$data;
  variables: useLaunchBettingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "launchBetting",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "launchUrl",
            "storageKey": null
          }
        ],
        "type": "ColossusBetsBettingLaunch",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "blockedCategory",
            "storageKey": null
          }
        ],
        "type": "LaunchBlockedTurnoverCategory",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "frozenCategory",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GamingFreeze",
            "kind": "LinkedField",
            "name": "freeze",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "freezeIssuer",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "expires",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "LaunchCategoryFrozen",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ageRequirement",
            "storageKey": null
          }
        ],
        "type": "LaunchRequiredAgeNotMet",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useLaunchBettingMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useLaunchBettingMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8561ecdcca9eafaeddf4bf26ea115643",
    "id": null,
    "metadata": {},
    "name": "useLaunchBettingMutation",
    "operationKind": "mutation",
    "text": "mutation useLaunchBettingMutation(\n  $input: LaunchBettingInput!\n) {\n  launchBetting(input: $input) {\n    __typename\n    ... on ColossusBetsBettingLaunch {\n      launchUrl\n    }\n    ... on LaunchBlockedTurnoverCategory {\n      blockedCategory\n    }\n    ... on LaunchCategoryFrozen {\n      frozenCategory\n      freeze {\n        freezeIssuer\n        expires\n      }\n    }\n    ... on LaunchRequiredAgeNotMet {\n      ageRequirement\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "96694e64a8c037c98ede2caa44e35386";

export default node;
