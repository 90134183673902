import styled from '@emotion/styled';
import { Breakpoint, Color } from '@pafcloud/style';

const oddRowBackground = `${Color.TextColors.BodyText}10`;

export const Table = styled.table({
  width: '100%',
  maxWidth: '100%',
  tableLayout: 'fixed',
  textAlign: 'left',
  borderCollapse: 'collapse',
  color: Color.TextColors.BodyText,
  tbody: {
    'tr:nth-of-type(even)': {
      backgroundColor: oddRowBackground,
    },
  },
  'th,td': {
    padding: '15px 20px',
  },
  th: {
    fontWeight: 'bold',
    verticalAlign: 'bottom',
    color: Color.TextColors.HighlightedText,
  },

  td: {
    whiteSpace: 'nowrap',
  },
  [Breakpoint.TabletOrLarger]: {
    td: {
      whiteSpace: 'normal',
    },
  },
});
