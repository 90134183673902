/**
 * @generated SignedSource<<27be90104bdd3d0c8e1d92d14032cee9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type GamingFreezeIssuer = "HAMPI" | "PLAYER" | "SOURCE_OF_FUNDS" | "SOURCE_OF_WEALTH";
export type LaunchErrorCode = "BLOCKED" | "DEMO_UNAVAILABLE" | "MAINTENANCE" | "NICKNAME_MISSING" | "SPANISH_GAME_SESSION_SERVICE_ERROR" | "UNKNOWN";
export type usePokerLaunchMutation$variables = Record<PropertyKey, never>;
export type usePokerLaunchMutation$data = {
  readonly launchPoker: {
    readonly __typename: "LaunchBlockedTurnoverCategory";
    readonly blockedCategory: string;
  } | {
    readonly __typename: "LaunchCategoryFrozen";
    readonly freeze: {
      readonly expires: string | null;
      readonly freezeIssuer: GamingFreezeIssuer;
    };
    readonly frozenCategory: string;
  } | {
    readonly __typename: "LaunchError";
    readonly error: LaunchErrorCode;
  } | {
    readonly __typename: "LaunchRequiredAgeNotMet";
    readonly ageRequirement: number;
  } | {
    readonly __typename: "PokerLaunch";
    readonly popupUrl: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type usePokerLaunchMutation = {
  response: usePokerLaunchMutation$data;
  variables: usePokerLaunchMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "input",
        "value": {
          "client": "WEB"
        }
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "launchPoker",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "popupUrl",
            "storageKey": null
          }
        ],
        "type": "PokerLaunch",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "error",
            "storageKey": null
          }
        ],
        "type": "LaunchError",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ageRequirement",
            "storageKey": null
          }
        ],
        "type": "LaunchRequiredAgeNotMet",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "frozenCategory",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GamingFreeze",
            "kind": "LinkedField",
            "name": "freeze",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "freezeIssuer",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "expires",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "LaunchCategoryFrozen",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "blockedCategory",
            "storageKey": null
          }
        ],
        "type": "LaunchBlockedTurnoverCategory",
        "abstractKey": null
      }
    ],
    "storageKey": "launchPoker(input:{\"client\":\"WEB\"})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "usePokerLaunchMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "usePokerLaunchMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "a5b4aaa4ed3a3401563769149d9d77ee",
    "id": null,
    "metadata": {},
    "name": "usePokerLaunchMutation",
    "operationKind": "mutation",
    "text": "mutation usePokerLaunchMutation {\n  launchPoker(input: {client: WEB}) {\n    __typename\n    ... on PokerLaunch {\n      popupUrl\n    }\n    ... on LaunchError {\n      error\n    }\n    ... on LaunchRequiredAgeNotMet {\n      ageRequirement\n    }\n    ... on LaunchCategoryFrozen {\n      frozenCategory\n      freeze {\n        freezeIssuer\n        expires\n      }\n    }\n    ... on LaunchBlockedTurnoverCategory {\n      blockedCategory\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "135553e11ac5b7421baca910c25bc62f";

export default node;
