import { useState } from 'react';
import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { toast } from 'react-toastify';
import styled from '@emotion/styled';
import { differenceInDays, parseISO } from 'date-fns';
import { useTranslation } from '@pafcloud/i18n';
import { Button } from '@pafcloud/base-components';
import { FontTextSize } from '@pafcloud/style';
import { useFreezeLevel } from '../useFreezeLevel';
import { LoyaltyProgramColors } from '../loyalty-program-colors';
import type {
  LoyaltyProgramFreeze_freezes$data,
  LoyaltyProgramFreeze_freezes$key,
} from './__generated__/LoyaltyProgramFreeze_freezes.graphql';

const loyaltyProgramFreezeFragment = graphql`
  fragment LoyaltyProgramFreeze_freezes on LoyaltyProgramPlayer {
    currentLevelFrozen
    remainingFreezes
    levelPeriodEndsAt
  }
`;

const FreezeCard = styled.div({
  display: 'flex',
  flexDirection: 'column',
  color: LoyaltyProgramColors.Text,
});

const FreezeButton = styled(Button)({
  whiteSpace: 'nowrap',
});

const FreezeInfo = styled.small({
  display: 'inline-block',
  width: '100%',
  marginTop: 4,
  fontSize: FontTextSize.Tiny,
  textAlign: 'center',
});

const FreezeButtonContainer = styled.div({
  display: 'flex',

  'button:not(:last-child)': {
    marginRight: 8,
  },
});

type LoyaltyProgramFreezeStateProps = {
  confirmFreeze: boolean;
  isLoading: boolean;
  levelIsFrozen: boolean;
  freezes: number;
};

type FreezeButtonStateProps = {
  state: LoyaltyProgramFreezeStateProps;
  setState: (state: LoyaltyProgramFreezeStateProps) => void;
};

type ConfirmOrCancelFreezeButtonsProps = FreezeButtonStateProps & {
  handleFreeze: () => Promise<void>;
};

const ConfirmOrCancelFreezeButtons: FC<ConfirmOrCancelFreezeButtonsProps> = ({ state, setState, handleFreeze }) => {
  const { t } = useTranslation('loyalty-program');
  return (
    <FreezeButtonContainer>
      <FreezeButton
        size="small"
        variant="secondary"
        type="submit"
        icon="closeFat"
        iconAlignment="LEFT"
        onClick={() => setState({ ...state, confirmFreeze: false })}
      >
        {t('freeze.level-cancel')}
      </FreezeButton>
      <FreezeButton
        size="small"
        variant="secondary"
        type="button"
        icon="check"
        iconAlignment="LEFT"
        isLoading={state.isLoading}
        onClick={handleFreeze}
      >
        {t('freeze.level-confirm')}
      </FreezeButton>
    </FreezeButtonContainer>
  );
};

type InitialFreezeButtonProps = FreezeButtonStateProps & {
  loyaltyProgram: LoyaltyProgramFreeze_freezes$data;
};

const InitialFreezeButton: FC<InitialFreezeButtonProps> = ({ state, setState, loyaltyProgram }) => {
  const { t } = useTranslation('loyalty-program');
  return (
    <>
      <FreezeButton
        size="small"
        variant="secondary"
        type="button"
        icon="pause"
        iconAlignment="LEFT"
        disabled={loyaltyProgram.currentLevelFrozen}
        onClick={() => setState({ ...state, confirmFreeze: true })}
      >
        {loyaltyProgram.currentLevelFrozen ? t('freeze.level-frozen') : t('freeze.keep-your-level')}
      </FreezeButton>

      {!loyaltyProgram.currentLevelFrozen && (
        <FreezeInfo>{t('freeze.remaining-freezes', { remainingFreezes: loyaltyProgram.remainingFreezes })}</FreezeInfo>
      )}
    </>
  );
};

type LoyaltyProgramFreezeProps = {
  loyaltyProgram: LoyaltyProgramFreeze_freezes$key | null;
};

export const LoyaltyProgramFreeze: FC<LoyaltyProgramFreezeProps> = (props) => {
  const loyaltyProgram = useFragment(loyaltyProgramFreezeFragment, props.loyaltyProgram);
  const { t } = useTranslation(['loyalty-program', 'common']);
  const freezeLevel = useFreezeLevel();
  const [state, setState] = useState({
    confirmFreeze: false,
    isLoading: false,
    levelIsFrozen: loyaltyProgram?.currentLevelFrozen ?? false,
    freezes: loyaltyProgram?.remainingFreezes ?? 0,
  });

  if (loyaltyProgram == null) {
    return null;
  }

  if (loyaltyProgram.remainingFreezes === 0 && !loyaltyProgram.currentLevelFrozen) {
    return null;
  }

  const { levelPeriodEndsAt } = loyaltyProgram;
  const parsedLevelPeriodEndsAt = parseISO(levelPeriodEndsAt);

  const today = new Date();
  const daysRemainingUntilReset = differenceInDays(parsedLevelPeriodEndsAt, today);

  const handleFreeze = async () => {
    setState((prev) => ({
      ...prev,
      isLoading: true,
    }));

    try {
      const response = await freezeLevel({});

      setState((prev) => ({
        ...prev,
        confirmFreeze: false,
        isLoading: false,
        freezes: response?.loyaltyProgram?.remainingFreezes ?? 0,
        levelIsFrozen: response?.loyaltyProgram?.currentLevelFrozen ?? false,
      }));
    } catch {
      setState((prev) => ({
        ...prev,
        isLoading: false,
      }));
      toast.error(t('common:errors.generic'));
      return;
    }
  };

  return (
    <FreezeCard>
      {state.confirmFreeze ? (
        <ConfirmOrCancelFreezeButtons state={state} setState={setState} handleFreeze={handleFreeze} />
      ) : (
        <InitialFreezeButton state={state} setState={setState} loyaltyProgram={loyaltyProgram} />
      )}

      {loyaltyProgram.currentLevelFrozen && (
        <FreezeInfo>
          {t('freeze.days-until-reset', {
            daysRemainingUntilReset,
          })}
        </FreezeInfo>
      )}
    </FreezeCard>
  );
};
