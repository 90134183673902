import type { FC } from 'react';
import { useId, useEffect } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { useAsync } from '@pafcloud/react-hook-utils';
import {
  BettingCard,
  BettingSection,
  BettingSportList,
  ShowMoreButton,
  fetchKambiEvent,
  useFetchKambiSports,
} from '@pafcloud/block-components';
import { GameListTitle } from '@pafcloud/game-components';
import { useQuery } from '@pafcloud/relay-helpers';
import type { BettingBlock_block$key } from './__generated__/BettingBlock_block.graphql';
import type { BettingBlockQuery } from './__generated__/BettingBlockQuery.graphql';

const blockFragment = graphql`
  fragment BettingBlock_block on BettingBlock {
    title
    link {
      text
      ariaLabel
      url
    }
    showSportLinks
    eventUrl
    promotedEvent {
      url
      image
    }
    promotedEventGroup {
      url
      image
    }
    sportsUrl
  }
`;

const bettingBlockQuery = graphql`
  query BettingBlockQuery($termKey: String!) {
    sport(termKey: $termKey) {
      cardImage {
        url
      }
    }
  }
`;

type Props = {
  block: BettingBlock_block$key;
};

const BettingBlock: FC<Props> = (props) => {
  const { eventUrl, sportsUrl, promotedEvent, promotedEventGroup, link, title, showSportLinks } = useFragment(
    blockFragment,
    props.block,
  );

  const querySportImage = useQuery<BettingBlockQuery>(bettingBlockQuery);

  const [state, loadEvent] = useAsync({
    onStart: async () => {
      const fallbackEvent = { url: eventUrl, image: null };

      const bettingEventPromises = [promotedEvent, promotedEventGroup, fallbackEvent].map(async (maybeEvent) => {
        if (maybeEvent?.url == null) {
          return null;
        }

        const event = await fetchKambiEvent(maybeEvent.url).catch(() => null);
        if (event == null) {
          return null;
        }

        // image is actually `string | '' | undefined`
        const image = maybeEvent.image?.length ? maybeEvent.image : undefined;

        return { event, image };
      });

      for await (const bettingEvent of bettingEventPromises) {
        if (bettingEvent == null) {
          continue;
        }

        if (bettingEvent.image == null) {
          const { sport } = await querySportImage({ termKey: bettingEvent.event.sport });

          bettingEvent.image = sport?.cardImage.url;
        }

        return bettingEvent;
      }

      return null;
    },
  });

  useEffect(loadEvent, [loadEvent]);

  const sports = useFetchKambiSports(sportsUrl);
  const accessibleNameId = useId();

  return (
    <BettingSection className="full-width">
      {title && <GameListTitle title={title} accessibleNameId={accessibleNameId} />}

      <BettingCard
        event={state.data?.event}
        thumbnail={state.data?.image}
        loading={state.status === 'idle' || state.status === 'pending'}
      />

      {showSportLinks && <BettingSportList sports={sports} />}

      {link && (
        <ShowMoreButton
          href={link.url}
          aria-label={link.ariaLabel ?? undefined}
          variant="secondary"
          colorstyle="accent"
          size="default"
        >
          {link.text}
        </ShowMoreButton>
      )}
    </BettingSection>
  );
};

export default BettingBlock;
