import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { useIsSSR } from '@pafcloud/react-hook-utils';
import { BettingClient, IframedKambiBettingClient, IframedScoutGamingBettingClient } from '@pafcloud/block-components';
import type { BettingClientBlock_block$key } from './__generated__/BettingClientBlock_block.graphql';

const blockFragment = graphql`
  fragment BettingClientBlock_block on BettingClientBlock {
    provider
  }
`;

type Props = {
  block: BettingClientBlock_block$key;
};

const BettingClientBlock: FC<Props> = (props) => {
  const { provider } = useFragment(blockFragment, props.block);

  const isSSR = useIsSSR();

  // These components has had issues with SSR in the past.
  // TODO: We should investigate if this hack can be removed.
  if (isSSR) {
    return null;
  }

  if (provider === 'KAMBI') {
    return <IframedKambiBettingClient />;
  }
  if (provider === 'SCOUT_GAMING') {
    return <IframedScoutGamingBettingClient />;
  }

  // Fallback to non-iframed betting client for providers without implementation
  return <BettingClient provider={provider} />;
};

export default BettingClientBlock;
