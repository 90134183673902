/**
 * @generated SignedSource<<303c46bfb40f94406058a2b8bde91be3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PokerAppStoreLink_data$data = {
  readonly iosApp: {
    readonly description: string;
    readonly url: string;
  } | null;
  readonly " $fragmentType": "PokerAppStoreLink_data";
};
export type PokerAppStoreLink_data$key = {
  readonly " $data"?: PokerAppStoreLink_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"PokerAppStoreLink_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PokerAppStoreLink_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AppDownload",
      "kind": "LinkedField",
      "name": "iosApp",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PokerLauncher",
  "abstractKey": null
};

(node as any).hash = "62b33d2dab07c63ba4cc87e5e0223008";

export default node;
