import styled from '@emotion/styled';
import { Color } from '@pafcloud/style';
import { DisclosureWidget } from '../disclosure';

export const Accordion = styled(DisclosureWidget)({
  width: 'min(100ex, 100%)',
  boxShadow: Color.Elevation.Level1,

  '--disclosure-background': Color.Surface.Base.Background,
  '--disclosure-border': `1px solid ${Color.Surface.Base.Dimmed}`,

  '--disclosure-summary-border': `1px solid ${Color.Surface.Base.Dimmed}`,
  '--disclosure-summary-text': Color.TextColors.HighlightedText,
});
