/**
 * @generated SignedSource<<0cbbde562845eba05faf8ada57b193fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Flow = "ABOUT_DEPOSIT_LIMITS" | "ABOUT_LOSS_LIMITS" | "ABOUT_SESSION_LIMITS" | "ABOUT_STAKE_LIMITS" | "ABOUT_YEARLY_LIMITS" | "BONUS" | "BONUS_TERMS" | "CALENDAR_PROMOTION" | "DEPOSIT" | "GAMING_OVERVIEW" | "LINK" | "LOGIN" | "MANUAL_REGISTRATION" | "PROMOTION" | "WITHDRAWAL";
import { FragmentRefs } from "relay-runtime";
export type useGetFlowUrl_button$data = {
  readonly bonusTermsDocumentId: string | null;
  readonly calendarPromotion: {
    readonly slug: string;
  } | null;
  readonly customLink: string | null;
  readonly flow: Flow;
  readonly playerProgramId: number | null;
  readonly programExternalId: string | null;
  readonly promotion: {
    readonly customFlowTitle: string | null;
    readonly customSlug: string | null;
    readonly promotionId: string;
  } | null;
  readonly " $fragmentType": "useGetFlowUrl_button";
};
export type useGetFlowUrl_button$key = {
  readonly " $data"?: useGetFlowUrl_button$data;
  readonly " $fragmentSpreads": FragmentRefs<"useGetFlowUrl_button">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useGetFlowUrl_button",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bonusTermsDocumentId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "flow",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customLink",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "playerProgramId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "programExternalId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Promotion",
      "kind": "LinkedField",
      "name": "promotion",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "promotionId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customSlug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customFlowTitle",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CalendarPromotion",
      "kind": "LinkedField",
      "name": "calendarPromotion",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Button",
  "abstractKey": null
};

(node as any).hash = "f81a4715ba7a78d2fd14eae69cf64fb3";

export default node;
