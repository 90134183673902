import type { FC } from 'react';
import styled from '@emotion/styled';
import type { CSSObject } from '@emotion/react';
import { Heading } from '@pafcloud/base-components';

import { Font, FontTextSize } from '@pafcloud/style';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import type { LinkContainerContentProps } from '../LinkContainer';

const getThemeStyle = (): CSSObject => {
  if ($buildEnv.theme === 'classic') {
    return {
      fontWeight: 'normal',
    };
  }

  if ($buildEnv.theme === 'dreams') {
    return {
      fontStyle: 'italic',
    };
  }

  if ($buildEnv.theme === 'momentum') {
    return {
      fontWeight: 800,
      fontStyle: 'italic',
    };
  }

  return {};
};

const CardHeading = styled(Heading)({
  margin: '0 0 0.25rem 0',
  fontSize: FontTextSize.Huge,
  fontFamily: Font.HeadingAlt,

  ...getThemeStyle(),
});

export const LinkContainerText: FC<LinkContainerContentProps> = (props) => {
  const { heading, description } = props;

  return (
    <>
      <CardHeading>{heading}</CardHeading>
      {description && <p>{description}</p>}
    </>
  );
};
