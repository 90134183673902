import type { FC } from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import { Table, HorizontalScrollShadow } from '@pafcloud/base-components';
import { RichText } from '@pafcloud/rich-text';
import type { TableBlock_block$key } from './__generated__/TableBlock_block.graphql';

const blockFragment = graphql`
  fragment TableBlock_block on TableBlock {
    tableHeaderRow
    tableRows
  }
`;

type Props = {
  block: TableBlock_block$key;
};

export const TableBlock: FC<Props> = (props) => {
  const block = useFragment(blockFragment, props.block);
  return (
    <RichText>
      <HorizontalScrollShadow>
        <Table>
          <tbody>
            {block.tableRows.map((cells, rowIndex) => {
              return (
                <tr key={rowIndex}>
                  {cells.map((cell, cellIndex) => {
                    if (rowIndex === 0 && block.tableHeaderRow) {
                      return (
                        <th key={cellIndex} style={{ width: `calc(100% / ${cells.length})` }}>
                          {cell}
                        </th>
                      );
                    }
                    return <td key={cellIndex}>{cell}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </HorizontalScrollShadow>
    </RichText>
  );
};
