import styled from '@emotion/styled';
import { SkeletonContent } from '@pafcloud/base-components';
import { Color, DynamicValues } from '@pafcloud/style';

export const BettingWrapper = styled.div({
  width: '100%',
  height: `calc(${DynamicValues.ViewportHeight} - ${DynamicValues.HeaderMaxHeight})`,
  margin: 0,
  minHeight: '70vh',
  borderBottom: `1px solid ${Color.Surface.Base.Dimmed}`,
});
export const BettingIframe = styled.iframe({
  width: '100%',
  height: '100%',
  border: 0,
  margin: 0,
});

export const BettingIframeSkeleton = styled(SkeletonContent)({
  width: '100%',
  height: '100%',
});
