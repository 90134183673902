import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { Promotions } from '@pafcloud/block-components';
import type { PromotionsBlock_block$key } from './__generated__/PromotionsBlock_block.graphql';

const blockFragment = graphql`
  fragment PromotionsBlock_block on PromotionsBlock {
    ...Promotions_block
  }
`;

type Props = {
  block: PromotionsBlock_block$key;
};

const PromotionsBlock: FC<Props> = (props) => {
  const block = useFragment(blockFragment, props.block);
  return <Promotions block={block} />;
};

export default PromotionsBlock;
