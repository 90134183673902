/**
 * @generated SignedSource<<754526ea2d9f599b222e1631e554dc75>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type HeroRibbonColor = "PRIMARY" | "SECONDARY";
export type HeroRibbonPlacement = "BOTTOM_LEFT" | "BOTTOM_RIGHT" | "TOP_LEFT" | "TOP_RIGHT";
import { FragmentRefs } from "relay-runtime";
export type Hero_common$data = {
  readonly background: {
    readonly " $fragmentSpreads": FragmentRefs<"HeroBackground_background">;
  } | null;
  readonly id: string;
  readonly name: string;
  readonly ribbon: {
    readonly color: HeroRibbonColor;
    readonly placement: HeroRibbonPlacement;
    readonly text: string;
  } | null;
  readonly " $fragmentType": "Hero_common";
};
export type Hero_common$key = {
  readonly " $data"?: Hero_common$data;
  readonly " $fragmentSpreads": FragmentRefs<"Hero_common">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Hero_common",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HeroRibbon",
      "kind": "LinkedField",
      "name": "ribbon",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "color",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "placement",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HeroBackground",
      "kind": "LinkedField",
      "name": "background",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HeroBackground_background"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CommonHeroFields",
  "abstractKey": "__isCommonHeroFields"
};

(node as any).hash = "3cc44207bb67176d5d53293bb659fd4e";

export default node;
