import styled from '@emotion/styled';
import Link from 'next/link';
import { useTranslation } from '@pafcloud/i18n';
import { ButtonLink } from '@pafcloud/base-components';
import { useBackLink, NO_PREVIOUS_PATH } from '@pafcloud/contexts';

const BackButtonLink = styled(Link)({
  alignSelf: 'flex-start',
  marginLeft: 'auto',
}).withComponent(ButtonLink);

export const BackLink = () => {
  const FALLBACK = '/';
  const { t } = useTranslation('common');
  const backLink = useBackLink();

  const href = backLink === NO_PREVIOUS_PATH ? FALLBACK : backLink;
  return (
    <BackButtonLink variant="secondary" size="small" href={href}>
      {t('back')}
    </BackButtonLink>
  );
};
