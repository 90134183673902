import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { MosaicGameList } from '@pafcloud/game-components';
import type { MosaicGameListBlock_block$key } from './__generated__/MosaicGameListBlock_block.graphql';

const blockFragment = graphql`
  fragment MosaicGameListBlock_block on MosaicGameListBlock {
    ...MosaicGameList_block
  }
`;

type Props = {
  block: MosaicGameListBlock_block$key;
};

const MosaicGameListBlock: FC<Props> = (props) => {
  const block = useFragment(blockFragment, props.block);
  return <MosaicGameList block={block} />;
};

export default MosaicGameListBlock;
