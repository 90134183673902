import type { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { Card } from '@pafcloud/base-components';

import { Breakpoint } from '@pafcloud/style';
import { LinkContainerButton } from './parts/LinkContainerButton';
import { LinkContainerFigure } from './parts/LinkContainerFigure';
import { LinkContainerText } from './parts/LinkContainerText';

const LINK_CONTAINER_BREAKPOINT = Breakpoint.LaptopOrLarger;

const DefaultCard = styled(Card)({
  padding: 24,
});

// Naming the div to style it inside different layouts
const Content = styled.div({
  flexBasis: '100%',
  flexGrow: 1,

  '> p': {
    margin: 0,
  },
});

const VerticalGrid = styled(DefaultCard)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flexBasis: '100%', // Equal height for all cards
  textAlign: 'center',

  '* + *': {
    marginTop: 24,
  },
});

const HorizontalGrid = styled(DefaultCard)({
  display: 'grid',
  gap: 24,
  gridTemplateRows: 'auto 1fr auto',
  justifyItems: 'center',
  alignItems: 'start',

  minHeight: 144,

  [`${Content}`]: {
    maxWidth: '70ex',
    textAlign: 'center',
  },

  [LINK_CONTAINER_BREAKPOINT]: {
    gridTemplateRows: 'unset',
    gridTemplateColumns: 'auto 1fr auto',
    justifyContent: 'stretch',
    justifyItems: 'unset',
    alignItems: 'center',

    [`${Content}`]: {
      textAlign: 'left',
    },

    // What ever comes after the content, align it to the end
    [`${Content} ~ *`]: {
      justifySelf: 'end',
    },
  },
});

export type LinkContainerContentProps = {
  heading: string;
  description?: string | null;
  image?: {
    src: string | null;
    altText: string | null;
  };
  figureIcon?: string | null;
  link: {
    href: string | null;
    linkText: string | null;
    ariaLabel: string | null;
  };
  children?: ReactNode;
};

const LinkContainerLayout: FC<LinkContainerContentProps> = (props) => {
  const { link, children } = props;

  return (
    <>
      <LinkContainerFigure {...props} />
      <Content>
        <LinkContainerText {...props} />
        {children}
      </Content>

      {link.href && <LinkContainerButton link={link} />}
    </>
  );
};

type LinkContainerProps = LinkContainerContentProps & {
  layout: 'VERTICAL' | 'HORIZONTAL';
};

export const LinkContainer: FC<LinkContainerProps> = ({ layout, ...props }) => {
  if (layout === 'HORIZONTAL') {
    return (
      <HorizontalGrid>
        <LinkContainerLayout {...props} />
      </HorizontalGrid>
    );
  }

  return (
    <VerticalGrid>
      <LinkContainerLayout {...props} />
    </VerticalGrid>
  );
};
