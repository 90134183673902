import type { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { BlockBackground, FullWidthImage } from '@pafcloud/base-components';
import { BackgroundContainerColors } from './background-container-colors';

const Background = styled.section({
  '--overlay-background-color': 'transparent',
  position: 'relative',

  backgroundColor: 'var(--overlay-background-color)',

  '&[data-color="dark"]': {
    '--overlay-background-color': `${BackgroundContainerColors.Dark}`,
  },
  '&[data-color="white"]': {
    '--overlay-background-color': `${BackgroundContainerColors.White}`,
  },
});

const BackgroundOverlay = styled.div({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 1,
  background: 'linear-gradient(0deg, var(--overlay-background-color) 0%, rgba(0,0,0,0) 100%)',
});

const BackgroundImage = styled(FullWidthImage)<{
  disableOpacity: boolean;
  position: string;
}>(({ disableOpacity, position }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 0,

  '> img': {
    objectPosition: position,
    opacity: disableOpacity ? undefined : 0.6,
  },
}));

const Content = styled(BlockBackground)({
  position: 'relative',
  zIndex: 2,
  '&&&': {
    paddingTop: 32,
    paddingBottom: 32,
  },
  boxShadow: 'none',
  backgroundColor: 'unset',
});

type BackgroundContainerProps = {
  position: string;
  backgroundContainerImage: string;
  disableOpacity: boolean;
  hasGradient: boolean;
  colors: string;
  children: ReactNode;
};

export const BackgroundContainer: FC<BackgroundContainerProps> = ({
  position,
  backgroundContainerImage,
  hasGradient,
  disableOpacity,
  children,
  colors,
}) => {
  return (
    <Background className="full-width" data-color={colors === 'None' ? undefined : colors.toLowerCase()}>
      {hasGradient && <BackgroundOverlay />}
      {backgroundContainerImage && (
        <BackgroundImage
          mobileImageUrl={backgroundContainerImage}
          desktopImageUrl={backgroundContainerImage}
          disableOpacity={disableOpacity}
          position={position}
        />
      )}

      <Content>{children}</Content>
    </Background>
  );
};
