import type { FC } from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import { ExternalLink } from '@pafcloud/base-components';
import type { PokerAppStoreLink_data$key } from './__generated__/PokerAppStoreLink_data.graphql';
import AppStoreBadgeSVG from './AppStoreBadge.svg';

const dataFragment = graphql`
  fragment PokerAppStoreLink_data on PokerLauncher {
    iosApp {
      description
      url
    }
  }
`;

type Props = {
  data: PokerAppStoreLink_data$key;
};

export const PokerAppStoreLink: FC<Props> = ({ data }) => {
  const { iosApp } = useFragment(dataFragment, data);

  if (iosApp === null) {
    return null;
  }

  return (
    <ExternalLink aria-label={iosApp.description} href={iosApp.url}>
      <AppStoreBadgeSVG />
    </ExternalLink>
  );
};
