/**
 * @generated SignedSource<<3fef00e45cac18b60001ee0ed3efd96d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BillboardGameListBlock_block$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BillboardGameList_block">;
  readonly " $fragmentType": "BillboardGameListBlock_block";
};
export type BillboardGameListBlock_block$key = {
  readonly " $data"?: BillboardGameListBlock_block$data;
  readonly " $fragmentSpreads": FragmentRefs<"BillboardGameListBlock_block">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BillboardGameListBlock_block",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillboardGameList_block"
    }
  ],
  "type": "BillboardGameListBlock",
  "abstractKey": null
};

(node as any).hash = "3273b39b8abe087a5435f930497c51f7";

export default node;
