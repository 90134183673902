import type { FC, ReactNode } from 'react';
import { useRef, useState } from 'react';
import FocusTrap from 'focus-trap-react';
import { Icon } from '../icon';
import { ActionList } from '../action-list';
import { GroupedTabPopup } from './GroupedTabPopup';
import { TAB_POPUP_HEIGHT, TAB_POPUP_WIDTH } from './groupedTabContants';

type Props = {
  title: string;
  selected?: boolean;
  className?: string;
  children: (close: () => void) => ReactNode;
};

export const GroupedTab: FC<Props> = ({ title, children, selected, className }) => {
  const [isActive, setIsActive] = useState(false);
  const [position, setPosition] = useState('bottomRight');
  const tabRef = useRef<HTMLDivElement | null>(null);

  const close = () => setIsActive(false);

  /** Calculate popup position before opening  */
  const clickTab = () => {
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    const rect = tabRef.current?.getBoundingClientRect() ?? { bottom: 0, top: 0, right: 0, left: 0 };

    const posY = screenHeight - rect.bottom < TAB_POPUP_HEIGHT && rect.top >= TAB_POPUP_HEIGHT ? 'top' : 'bottom';
    const posX = rect.right + TAB_POPUP_WIDTH > screenWidth ? 'Left' : 'Right';
    const newPos = `${posY}${posX}`;

    setPosition(newPos);
    setIsActive(!isActive);
  };

  return (
    <FocusTrap
      active={isActive}
      focusTrapOptions={{
        escapeDeactivates: true,
        clickOutsideDeactivates: true,
        onDeactivate: close,
      }}
    >
      <div style={{ position: 'relative', display: 'flex' }} ref={tabRef}>
        <button //
          role="tab"
          onClick={clickTab}
          className={className}
          aria-haspopup="true"
          aria-expanded={isActive}
          aria-selected={selected}
        >
          {title}
          <Icon name="kebab" size={16} />
        </button>

        {isActive && (
          <GroupedTabPopup onClose={close} title={title} position={position}>
            <ActionList>{children(close)}</ActionList>
          </GroupedTabPopup>
        )}
      </div>
    </FocusTrap>
  );
};
