import { useEffect } from 'react';
import { useHandler, usePoller } from '@pafcloud/react-hook-utils';

type UseBingoProps = {
  refetch: () => void;
};

export const useBingo = ({ refetch }: UseBingoProps) => {
  const { startPolling, stopPolling } = usePoller({
    interval: 3000,
    onError: (error) => console.error('Polling failed with error', { error }),
  });

  const fetchAndSave = useHandler(refetch);

  useEffect(() => {
    startPolling(async () => {
      void fetchAndSave();
      return true;
    });
    return () => stopPolling();
  }, [startPolling, stopPolling, fetchAndSave]);
};
