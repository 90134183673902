import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { Hero } from '@pafcloud/block-components';
import type { HeroBlock_block$key } from './__generated__/HeroBlock_block.graphql';

const blockFragment = graphql`
  fragment HeroBlock_block on HeroBlock {
    hero {
      ...Hero_common
      ...Hero_block
    }
  }
`;

type HeroBlockProps = {
  block: HeroBlock_block$key;
  firstInMain?: boolean;
};

const HeroBlock: FC<HeroBlockProps> = ({ block, firstInMain }) => {
  const { hero } = useFragment(blockFragment, block);

  if (hero == null) {
    return null;
  }

  return <Hero block={hero} common={hero} firstInMain={firstInMain} />;
};

export default HeroBlock;
