/**
 * @generated SignedSource<<d5764aab83dc4a1585243ba828dec2f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PosterGameListBlock_block$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PosterGameList_block">;
  readonly " $fragmentType": "PosterGameListBlock_block";
};
export type PosterGameListBlock_block$key = {
  readonly " $data"?: PosterGameListBlock_block$data;
  readonly " $fragmentSpreads": FragmentRefs<"PosterGameListBlock_block">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PosterGameListBlock_block",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PosterGameList_block"
    }
  ],
  "type": "PosterGameListBlock",
  "abstractKey": null
};

(node as any).hash = "415d6a7613313ae2c0cbb864168bee20";

export default node;
