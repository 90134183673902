import styled from '@emotion/styled';
import { graphql, useFragment } from 'react-relay/hooks';
import { useId } from 'react';
import type { FC } from 'react';
import { parseISO } from 'date-fns';
import { Heading } from '@pafcloud/base-components';
import { useTranslation } from '@pafcloud/i18n';
import { Breakpoint, Font } from '@pafcloud/style';
import { useFormatDate } from '@pafcloud/react-hook-utils';
import { LoyaltyProgramColors } from '../loyalty-program-colors';
import { useCalculateProgress } from '../useCalculateProgress';
import { getPlayerLevelImage } from './getPlayerLevelImage';
import { LoyaltyProgramProgressBar } from './LoyaltyProgramProgressBar';
import { LoyaltyProgramPayout } from './LoyaltyProgramPayout';
import type { LoyaltyProgramProgress_block$key } from './__generated__/LoyaltyProgramProgress_block.graphql';
import type { LoyaltyProgramProgress_player$key } from './__generated__/LoyaltyProgramProgress_player.graphql';
import { LoyaltyProgramProgressInformation } from './LoyaltyProgramProgressInformation';
import { LoyaltyProgramFreeze } from './LoyaltyProgramFreeze';
import { getProgressDetails } from './getProgressDetails';

const loyaltyProgramProgressFragment = graphql`
  fragment LoyaltyProgramProgress_block on LoyaltyProgramProgressBlock {
    loyaltyLevels {
      level
      maintenancePoints
      minimumPoints
      multiplier
      ...LoyaltyProgramLevelImages_image
    }
  }
`;

const playerFragment = graphql`
  fragment LoyaltyProgramProgress_player on Player {
    loyaltyProgram {
      levelPeriodEndsAt

      ...LoyaltyProgramPayout_payout
      ...LoyaltyProgramFreeze_freezes
    }
    ...useCalculateProgress_player
  }
`;

export const LoyaltyHeroSection = styled.section({
  marginTop: 0,
  padding: '48px var(--page-margin)',
  background: LoyaltyProgramColors.ProgressSectionBackground,
  backgroundSize: '100% 100%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'top right',

  borderBottom: LoyaltyProgramColors.ProgressSectionDivider,
});

export const Container = styled.div({
  width: 'min(900px, 100%)',
  margin: '0 auto',
  padding: 16,
  background: LoyaltyProgramColors.ProgressContainerBackground,

  [Breakpoint.LaptopOrLarger]: {
    padding: '24px 32px',
  },
});

const Progress = styled.div({
  marginBottom: 8,
  fontSize: 'clamp(1rem, 0.2857rem + 1.8571vw, 1.75rem)',
});

const ProgressHeading = styled(Heading)({
  margin: 0,
  fontFamily: Font.Heading,
  fontWeight: 900,
  fontStyle: LoyaltyProgramColors.HeadingStyle,
  color: LoyaltyProgramColors.Heading,
  fontSize: '2em',
  textAlign: 'center',
});

const RecalculationTitle = styled.div({
  fontSize: '0.875em',
  color: LoyaltyProgramColors.Text,
});

const LevelInformation = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 32,
  marginBottom: 32,

  time: {
    display: 'block',
  },

  [Breakpoint.LaptopOrLarger]: {
    time: {
      display: 'inline',
    },
  },
});

type Props = {
  block: LoyaltyProgramProgress_block$key;
  player: LoyaltyProgramProgress_player$key;
};

export const LoyaltyProgramProgress: FC<Props> = (props) => {
  const { t } = useTranslation('loyalty-program');
  const formatDate = useFormatDate();

  const headingId = useId();

  const { loyaltyLevels: levels } = useFragment(loyaltyProgramProgressFragment, props.block);
  const player = useFragment(playerFragment, props.player);

  const calculatedProgress = useCalculateProgress(player, levels);

  if (levels == null || player == null || calculatedProgress == null || player.loyaltyProgram == null) {
    return null;
  }

  const { maintainPoints, requiredValue, requiredPoints, playerLevel } = calculatedProgress;

  const levelImage = getPlayerLevelImage(levels, playerLevel);
  const levelMultiplier = levels.find((level) => level.level === playerLevel)?.multiplier ?? null;
  const isMaintainCompleted = requiredValue >= maintainPoints;
  const isMaxLevel = playerLevel === levels.length;

  const parsedLevelPeriodEndsAt = parseISO(player.loyaltyProgram.levelPeriodEndsAt);

  const { maintainCompleted } = getProgressDetails(isMaxLevel, maintainPoints, requiredValue, requiredPoints);

  const hideMaintain = isMaxLevel && maintainCompleted;

  return (
    <LoyaltyHeroSection className="full-width" aria-labelledby={headingId}>
      <Container>
        <Progress>
          <ProgressHeading id={headingId}>{t('default.title')}</ProgressHeading>

          <LoyaltyProgramProgressInformation
            levelImage={levelImage}
            maintainPoints={maintainPoints}
            requiredPoints={requiredPoints}
            requiredValue={requiredValue}
            levelMultiplier={levelMultiplier}
            isMaxLevel={isMaxLevel}
          />
          {!hideMaintain && (
            <LoyaltyProgramProgressBar
              maintainPoints={maintainPoints}
              requiredPoints={requiredPoints}
              requiredValue={requiredValue}
              isMaxLevel={isMaxLevel}
            />
          )}
        </Progress>

        <LevelInformation>
          <RecalculationTitle>
            {t('points.next-recalculation')}
            {` `}
            <time dateTime={parsedLevelPeriodEndsAt.toDateString()}>
              {formatDate(parsedLevelPeriodEndsAt, {
                dateStyle: 'full',
              })}
            </time>
          </RecalculationTitle>

          {!isMaintainCompleted && <LoyaltyProgramFreeze loyaltyProgram={player.loyaltyProgram} />}
        </LevelInformation>

        <LoyaltyProgramPayout loyaltyProgram={player.loyaltyProgram} />
      </Container>
    </LoyaltyHeroSection>
  );
};
