import type { FC } from 'react';
import { useEffect } from 'react';
import dynamic from 'next/dynamic';
import styled from '@emotion/styled';
import { toast } from 'react-toastify';
import { ZIndex } from '@pafcloud/style';
import { useTranslation } from '@pafcloud/i18n';
import { LoadingIndicator } from '@pafcloud/base-components';
import { useIsLoggedIn, usePlayerStatus } from '@pafcloud/contexts';
import { isType } from '@pafcloud/collection-utils';
import { AccessGuardLoadingIndicator } from '../access-guard/guards/AccessGuardLoadingIndicator';
import { useLaunchBetting } from './useLaunchBetting';
import { BettingWrapper } from './BettingWrapper';

const ColossusBetsBettingClient = dynamic(() => import('./ColossusBetsBettingClient'), {
  ssr: false,
});
const GameFreezeGuard = dynamic(() => import('../access-guard/guards/game-freeze/GameFreezeGuard'), {
  loading: () => <AccessGuardLoadingIndicator />,
});
const AgeLimitGuard = dynamic(() => import('../access-guard/guards/age-limit-guard/AgeLimitGuard'), {
  loading: () => <AccessGuardLoadingIndicator />,
});
const TurnoverGuard = dynamic(() => import('../access-guard/guards/turnover-guard/TurnoverGuard'), {
  loading: () => <AccessGuardLoadingIndicator />,
});

const CenteredLoadingIndicator = styled(LoadingIndicator)({
  display: 'block',
  margin: '40vh auto',
});

const ClientWrapper = styled.section({
  marginTop: 0,
  '&.full-width': {
    overflowY: 'unset',
  },

  '#KambiBC-container .KambiBC-js-bottom-navigation-container': {
    zIndex: ZIndex.KambiMobileNav,
  },
});

type BettingClientProps = {
  provider: 'COLOSSUS_BETS';
};

const Client: FC<BettingClientProps> = ({ provider }) => {
  const isLoggedIn = useIsLoggedIn();
  const { t, i18n } = useTranslation('common');

  const [{ status, data: bettingLaunch }, launchBetting] = useLaunchBetting((mutate) => ({
    onStart: () => mutate({ provider }),
    onError: () => toast.error(t('errors.generic')),
  }));

  useEffect(() => {
    // When the language or the player status has changed we need reload the betting client.
    launchBetting();
  }, [launchBetting, provider, i18n.language, isLoggedIn]);

  if (status === 'pending') {
    return <CenteredLoadingIndicator />;
  }

  if (isType(bettingLaunch, 'ColossusBetsBettingLaunch')) {
    return <ColossusBetsBettingClient config={bettingLaunch} />;
  }

  if (isType(bettingLaunch, 'LaunchCategoryFrozen')) {
    return <GameFreezeGuard category={bettingLaunch.frozenCategory} freeze={bettingLaunch.freeze} />;
  }

  if (isType(bettingLaunch, 'LaunchBlockedTurnoverCategory')) {
    return <TurnoverGuard category={bettingLaunch.blockedCategory} bettingProvider={provider} />;
  }

  if (isType(bettingLaunch, 'LaunchRequiredAgeNotMet')) {
    return <AgeLimitGuard requiredAge={bettingLaunch.ageRequirement} />;
  }

  return null;
};

export const BettingClient: FC<BettingClientProps> = ({ provider }) => {
  const playerStatus = usePlayerStatus();

  if (playerStatus === 'LOADING') {
    return (
      <BettingWrapper>
        <LoadingIndicator center />
      </BettingWrapper>
    );
  }

  return (
    <ClientWrapper className="full-width">
      <Client provider={provider} />
    </ClientWrapper>
  );
};
