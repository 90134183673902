import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { GameplayGameList } from '@pafcloud/game-components';
import type { GameplayGameListBlock_block$key } from './__generated__/GameplayGameListBlock_block.graphql';

const blockFragment = graphql`
  fragment GameplayGameListBlock_block on GameplayGameListBlock {
    ...GameplayGameList_block
  }
`;

type Props = {
  block: GameplayGameListBlock_block$key;
};

const GameplayGameListBlock: FC<Props> = (props) => {
  const block = useFragment(blockFragment, props.block);
  return <GameplayGameList block={block} />;
};

export default GameplayGameListBlock;
