import type { FC } from 'react';
import styled from '@emotion/styled';
import { useFragment, graphql } from 'react-relay/hooks';
import { GridList } from '@pafcloud/base-components';
import type { PromotionsGrid_promotions$key } from './__generated__/PromotionsGrid_promotions.graphql';
import { PromotionCard } from './PromotionCard';

const promotionsFragment = graphql`
  fragment PromotionsGrid_promotions on PromotionCard @relay(plural: true) {
    ...PromotionCard_promotion
  }
`;

type Props = {
  promotions: PromotionsGrid_promotions$key;
};

const PromotionGridList = styled(GridList)({
  paddingLeft: 'var(--full-width-margin)',
  paddingRight: 'var(--full-width-margin)',
});

export const PromotionsGrid: FC<Props> = (props) => {
  const promotions = useFragment(promotionsFragment, props.promotions);

  return (
    <PromotionGridList>
      {promotions.map((promotion, index) => (
        <PromotionCard key={index} promotion={promotion} />
      ))}
    </PromotionGridList>
  );
};
