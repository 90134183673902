import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { Search, SearchContainer } from '@pafcloud/block-components';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import type { SearchBlock_block$key } from './__generated__/SearchBlock_block.graphql';

const blockFragment = graphql`
  fragment SearchBlock_block on SearchBlock {
    search {
      gameListId
    }
  }
`;

type Props = {
  block: SearchBlock_block$key;
};

const SearchBlock: FC<Props> = (props) => {
  const block = useFragment(blockFragment, props.block);

  if ($buildEnv.site === 'x3000.com') {
    return null;
  }
  if ($buildEnv.site === 'x3000.ee') {
    return null;
  }
  if ($buildEnv.site === 'x3000.lv') {
    return null;
  }
  if ($buildEnv.site === '11.lv') {
    return null;
  }
  if ($buildEnv.site === 'speedybet.es') {
    return null;
  }
  if ($buildEnv.site === 'goldenbull.es') {
    return null;
  }
  if ($buildEnv.site === 'goldenbull.se') {
    return null;
  }
  if ($buildEnv.site === 'pinatacasino.es') {
    return null;
  }

  return (
    <SearchContainer>
      <Search key={block.search.gameListId} gameListId={block.search.gameListId} />
    </SearchContainer>
  );
};

export default SearchBlock;
