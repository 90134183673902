import styled from '@emotion/styled';
import type { FC } from 'react';
import { Breakpoint } from '@pafcloud/style';
import { InfoSausage } from '../info-sausage';

const Sausage = styled(InfoSausage)({
  position: 'absolute',
  top: 0,
  margin: '0 8px',
  transform: 'translateY(-50%)',
  '> *': {
    margin: '0 auto',
  },
  [Breakpoint.BigScreenOrLarger]: {
    position: 'unset',
    top: 'unset',
    left: 'unset',
    transform: 'unset',
  },
});

type CardInfoSausageProps = {
  text: string;
  icon?: string;
  className?: string;
};

export const CardInfoSausage: FC<CardInfoSausageProps> = ({ text, icon, className }) => {
  return <Sausage className={className} text={text} icon={icon} />;
};
