import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import type { BlockRenderer_blocks$key } from './__generated__/BlockRenderer_blocks.graphql';
import { blockComponents } from './blockComponents';

const blocksFragment = graphql`
  fragment BlockRenderer_blocks on Block @relay(plural: true) {
    __typename
    ... on CommonBlock {
      id
    }
    ...BettingBlock_block
    ...BettingClientBlock_block
    ...BettingListBlock_block
    ...BonusTermsBlock_block
    ...GameShortcutBlock_block
    ...HeroBlock_block
    ...IframeBlock_block
    ...InformationContainerBlock_block
    ...LinkContainerBlock_block
    ...ProgramGroupBlock_block
    ...MultiplayerBingoListBlock_block
    ...PokerLauncherBlock_block
    ...PromotionsBlock_block
    ...RichTextBlock_block
    ...SearchBlock_block
    ...TabGroupLinksBlock_block
    ...TableBlock_block
    ...GameplayGameListBlock_block
    ...PosterGameListBlock_block
    ...BillboardGameListBlock_block
    ...NumberedGameListBlock_block
    ...MosaicGameListBlock_block
    ...GridGameListBlock_block
    ...HighlightGameListBlock_block
    ...LoyaltyProgramDetailsBlock_block
    ...LoyaltyProgramProgressBlock_block
  }
`;

export const getBlockComponent = (type: string, components: typeof blockComponents) => {
  if (type in components) {
    return components[type as keyof typeof components];
  }
  return null;
};

type Props = {
  blocks: BlockRenderer_blocks$key;
};

export const BlockRenderer: FC<Props> = (props) => {
  const blocks = useFragment(blocksFragment, props.blocks);

  return (
    <>
      {blocks.map((block) => {
        const Component = getBlockComponent(block.__typename, blockComponents);

        if (Component) {
          return <Component key={block.id} block={block} />;
        }
        return null;
      })}
    </>
  );
};
