import { graphql } from 'relay-runtime';
import { createUseAsyncMutation } from '@pafcloud/relay-helpers';
import type { useLaunchBettingMutation } from './__generated__/useLaunchBettingMutation.graphql';

const createUseLaunchBetting = createUseAsyncMutation<useLaunchBettingMutation>(graphql`
  mutation useLaunchBettingMutation($input: LaunchBettingInput!) {
    launchBetting(input: $input) {
      __typename
      ... on ColossusBetsBettingLaunch {
        launchUrl
      }
      ... on LaunchBlockedTurnoverCategory {
        blockedCategory
      }
      ... on LaunchCategoryFrozen {
        frozenCategory
        freeze {
          freezeIssuer
          expires
        }
      }
      ... on LaunchRequiredAgeNotMet {
        ageRequirement
      }
    }
  }
`);

export const useLaunchBetting = createUseLaunchBetting((result) => {
  return result.launchBetting;
});
