import type { FC } from 'react';
import type { CSSObject } from '@emotion/react';
import styled from '@emotion/styled';
import { Color, Font } from '@pafcloud/style';

type Placement = 'TOP_LEFT' | 'TOP_RIGHT' | 'BOTTOM_LEFT' | 'BOTTOM_RIGHT';

const getPlacement = (placement: Placement) => {
  switch (placement) {
    case 'TOP_RIGHT':
      return {
        top: 0,
        right: 0,
      };
    case 'BOTTOM_LEFT':
      return {
        bottom: 0,
        left: 0,
      };
    case 'BOTTOM_RIGHT':
      return {
        bottom: 0,
        right: 0,
      };

    default:
      return {
        top: 0,
        left: 0,
      };
  }
};

const getDegree = (placement: Placement) => {
  switch (placement) {
    case 'TOP_RIGHT':
      return '45deg';
    case 'BOTTOM_LEFT':
      return '-315deg';

    default:
      return '-45deg';
  }
};

// Ribbon offset, adjusts the ribbon to not get clipped in the corner of the Wrapper
const getRibbonOffset = (placement: Placement) => {
  switch (placement) {
    case 'TOP_RIGHT':
      return {
        top: '-2rem',
        left: '2rem',
      };
    case 'BOTTOM_LEFT':
      return {
        top: '2rem',
        left: '-2rem',
      };
    case 'BOTTOM_RIGHT':
      return {
        top: '2rem',
        left: '2rem',
      };

    default:
      return {
        top: '-2rem',
        left: '-2rem',
      };
  }
};

const sharedBeforeAndAfterStyles: CSSObject = {
  content: "''",
  position: 'absolute',
  margin: '0 -1px', // For filling the gap.
  top: 0,
  width: '100%',
  height: '100%',
  background: 'inherit',
};

const Text = styled.span<{ placement: Placement; primary: boolean }>(({ primary, placement }) => {
  return {
    position: 'relative',
    ...getRibbonOffset(placement),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minWidth: 'min(128px, 8vw)',
    maxWidth: 320,
    transform: `rotate(${getDegree(placement)})`,
    margin: 0,
    padding: '1rem',

    background: primary ? Color.Primitive.Accent : Color.Primitive.Secondary,
    fontSize: '1.2rem',
    fontWeight: 'bold',
    fontFamily: Font.Primary,
    textAlign: 'center',
    color: primary ? Color.Primitive.AccentContrast : Color.Primitive.SecondaryContrast,

    '&::before': {
      ...sharedBeforeAndAfterStyles,
      left: '100%',
    },
    '&::after': {
      ...sharedBeforeAndAfterStyles,
      right: '100%',
    },
  };
});

const TextRibbon = styled.div<{ placement: Placement }>(({ placement }) => ({
  ...getPlacement(placement),
  position: 'absolute',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  aspectRatio: '1/1',
  width: 'max-content',
  maxHeight: '100%',
  padding: '1rem',

  // iOS 14 does not support aspect-ratio
  '@supports not (aspect-ratio: 1/1)': {
    '::before': {
      paddingTop: '100%',
      content: '""',
    },
  },
}));

type RibbonProps = {
  text: string;
  color: 'PRIMARY' | 'SECONDARY';
  placement: Placement;
};

export const Ribbon: FC<RibbonProps> = ({ text, color, placement }) => {
  const primary = color === 'PRIMARY';

  return (
    <TextRibbon placement={placement}>
      <Text primary={primary} placement={placement}>
        {text}
      </Text>
    </TextRibbon>
  );
};
