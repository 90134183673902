/**
 * @generated SignedSource<<3ba238b443f575f23ba56ec16ba9d8fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type PromotionsBlockDisplayType = "GRID" | "ROW";
import { FragmentRefs } from "relay-runtime";
export type Promotions_block$data = {
  readonly displayType: PromotionsBlockDisplayType;
  readonly link: {
    readonly ariaLabel: string | null;
    readonly text: string;
    readonly url: string;
  } | null;
  readonly promotions: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"PromotionsGrid_promotions" | "PromotionsRow_promotions">;
  }>;
  readonly title: string | null;
  readonly " $fragmentType": "Promotions_block";
};
export type Promotions_block$key = {
  readonly " $data"?: Promotions_block$data;
  readonly " $fragmentSpreads": FragmentRefs<"Promotions_block">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Promotions_block",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Link",
      "kind": "LinkedField",
      "name": "link",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ariaLabel",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PromotionCard",
      "kind": "LinkedField",
      "name": "promotions",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PromotionsRow_promotions"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PromotionsGrid_promotions"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PromotionsBlock",
  "abstractKey": null
};

(node as any).hash = "c37bd8bfd95fca090e889baafe3d9031";

export default node;
