import { useCallback, useEffect, useRef, useState } from 'react';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { useThrottle } from '@pafcloud/react-hook-utils';
import { useKeepAlive } from '../sync-app-data/useKeepAlive';

const SESSION_LENGTH = 20 * 60 * 1000; // 20min
const BETTING_SESSION_LENGTH = 60 * 60 * 1000; // 60min
const BETTING_TIMEOUT = 4 * 60 * 1000; // 4min

const TIMES_TO_RUN = Math.round((BETTING_SESSION_LENGTH - SESSION_LENGTH) / BETTING_TIMEOUT);

export const useBettingKeepAlive = () => {
  type MaybeTimeout = ReturnType<typeof setTimeout> | null;
  const timeoutRef = useRef<MaybeTimeout>(null);
  const [current, setCurrent] = useState(0);
  const keepAlive = useKeepAlive();
  const { throttledFn: throttledKeepAlive, cancel } = useThrottle(keepAlive, { wait: BETTING_TIMEOUT });

  const start = useCallback(() => {
    setCurrent(0);
    return throttledKeepAlive();
  }, [throttledKeepAlive]);

  const stop = useCallback(() => {
    setCurrent(TIMES_TO_RUN);
  }, []);

  useEffect(() => {
    const clear = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };

    if (current < TIMES_TO_RUN && $buildEnv.market !== 'spain') {
      clear();
      timeoutRef.current = setTimeout(() => {
        setCurrent((c) => c + 1);

        cancel();
        void keepAlive().then((success) => {
          if (!success) {
            // Stop timer if keepAlive failed
            stop();
          }
        });
      }, BETTING_TIMEOUT);
    }
    return clear;
  }, [cancel, current, keepAlive, stop]);

  return {
    keepAlive: start,
    stopKeepAlive: stop,
  };
};
