import type { FC } from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import styled from '@emotion/styled';
import { useIsSSR, useMedia } from '@pafcloud/react-hook-utils';
import { Breakpoint, PrefersReducedMotion } from '@pafcloud/style';
import { FullWidthBackground, FullWidthImage } from '@pafcloud/base-components';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import type { HeroType } from '../Hero';
import { heroBackgroundFallback } from './hero-background-fallback/heroBackgroundFallback';
import type { HeroBackground_background$key } from './__generated__/HeroBackground_background.graphql';
import { Video } from './backgroundStyles';

const backgroundFragment = graphql`
  fragment HeroBackground_background on HeroBackground {
    image {
      url
    }
    mobileImage {
      url
    }
    video {
      url
    }
    mobileVideo {
      url
    }
  }
`;

const BackgroundImage = styled(FullWidthImage)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

const prefersReducedMotion = (imageUrl: string | undefined, noMotion: boolean) => {
  if (imageUrl == null) {
    return true;
  }

  if (!noMotion) {
    return false;
  }

  return imageUrl.endsWith('.gif') || imageUrl.endsWith('.avif');
};

type VideoBackgroundProps = {
  videoUrl: string;
  autoPlay?: boolean;
};

const VideoBackground: FC<VideoBackgroundProps> = ({ videoUrl, autoPlay = true }) => {
  return (
    <FullWidthBackground data-testid="background-video">
      <Video
        /**
         * @param preload
         * @param poster
         * autoplay takes precedence over preload, making preload obsolete
         * Also, as we're implicitly preloading, the poster attribute
         * is obsolete as modern browsers™ use the first available frame
         * as poster until the full video is available.
         */
        src={videoUrl}
        autoPlay={autoPlay}
        muted
        loop
        playsInline
      />
    </FullWidthBackground>
  );
};

type HeroBackgroundComponentProps = {
  background: HeroBackground_background$key | null;
  gameBackground: string | null | undefined;
  heroType: HeroType;
  loading?: 'lazy' | 'eager';
};

export const HeroBackground: FC<HeroBackgroundComponentProps> = ({ gameBackground, heroType, loading, ...props }) => {
  const background = useFragment(backgroundFragment, props.background);
  const isSSR = useIsSSR();
  const { image, mobileImage } = background ?? {};
  const { video, mobileVideo } = background ?? {};
  const isPhone = useMedia(Breakpoint.Phone);
  const noMotion = useMedia(PrefersReducedMotion, false);

  if ($buildEnv.theme === 'dreams') {
    if (mobileVideo?.url != null && !noMotion) {
      if (isSSR && mobileVideo?.url != null) {
        return <VideoBackground videoUrl={mobileVideo.url} />;
      }
    }
  }

  // TODO: When NAC becomes golden it should use this as well, but while they
  // are still NAC/NAB those sites should use the fallback background images
  if ($buildEnv.theme === 'goldenV2' && video?.url == null && image?.url == null) {
    const createFallbackVideoUrl = (type: 'desktop' | 'mobile') =>
      `/static/videos/hero-backgrounds/golden-chips-${type}.mp4`;

    if (isPhone) {
      return <VideoBackground videoUrl={createFallbackVideoUrl('mobile')} autoPlay={!noMotion} />;
    }

    return <VideoBackground videoUrl={createFallbackVideoUrl('desktop')} autoPlay={!noMotion} />;
  }

  if (!isSSR && !noMotion) {
    if (!isPhone && video?.url != null) {
      return <VideoBackground videoUrl={video.url} />;
    }

    if (isPhone && mobileVideo?.url != null) {
      return <VideoBackground videoUrl={mobileVideo.url} />;
    }
  }

  const getBackgroundImage = () => {
    const fallback = {
      mobile: heroBackgroundFallback(heroType).mobileImage.url,
      desktop: heroBackgroundFallback(heroType).image.url,
    };

    const cmsImage = {
      mobile: mobileImage?.url ?? fallback.mobile,
      desktop: image?.url ?? fallback.desktop,
    };

    // If image contains motion and the user has reduced motion enabled, we need to use the fallback image.
    const preferedImage = {
      mobile: prefersReducedMotion(mobileImage?.url, noMotion) ? fallback.mobile : cmsImage.mobile,
      desktop: prefersReducedMotion(image?.url, noMotion) ? fallback.desktop : cmsImage.desktop,
    };

    // Some games don't have their own background. In those cases we need to bypass the "background" image and go
    // straight to the fallback background.
    return {
      mobile: gameBackground ?? preferedImage.mobile,
      desktop: gameBackground ?? preferedImage.desktop,
    };
  };

  return (
    <FullWidthBackground data-testid="background-image">
      <BackgroundImage
        mobileImageUrl={getBackgroundImage().mobile}
        desktopImageUrl={getBackgroundImage().desktop}
        shouldBlur={!!gameBackground}
        loading={loading}
      />
    </FullWidthBackground>
  );
};
