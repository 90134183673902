/**
 * @generated SignedSource<<354388c9c56f4491614327d2dc3547df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PromotionsRow_promotions$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"PromotionCard_promotion">;
  readonly " $fragmentType": "PromotionsRow_promotions";
}>;
export type PromotionsRow_promotions$key = ReadonlyArray<{
  readonly " $data"?: PromotionsRow_promotions$data;
  readonly " $fragmentSpreads": FragmentRefs<"PromotionsRow_promotions">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "PromotionsRow_promotions",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PromotionCard_promotion"
    }
  ],
  "type": "PromotionCard",
  "abstractKey": null
};

(node as any).hash = "03a7ffcde082b6a22f7ed75fd126e3ee";

export default node;
