import type { FC } from 'react';
import { useRouter } from 'next/router';
import type { NextRouter } from 'next/router';
import type { InternalLinkProps } from '@pafcloud/base-components';
import { InternalLink } from '@pafcloud/base-components';
import type { GraphqlTypes as gt } from '@pafcloud/graphql-schema/types';

type Props = InternalLinkProps & {
  tag?: gt.Tag;
  selected?: boolean;
};

export const GameListFilterLink: FC<Props> = ({ children, tag, ...props }) => {
  const url = getTagUrl(useRouter(), tag);

  return (
    <InternalLink href={url} {...props} aria-selected={props.selected} scroll={false} shallow>
      {children}
    </InternalLink>
  );
};

function getTagUrl(router: NextRouter, tag: gt.Tag | undefined) {
  const { query } = router;
  const slug = Array.isArray(query.slug) ? query.slug?.join('/') : (query.slug ?? '');
  return tag?.slug ? `${slug}?tag=${tag.slug}` : slug;
}
