import type { FC, SyntheticEvent } from 'react';
import { useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { dispatchTrackingEvent } from '@pafcloud/tracking';
import { useTranslation } from '@pafcloud/i18n';
import { useIsLoggedIn } from '@pafcloud/contexts';
import { useViewportHeight } from '../header/utils/useViewportHeight';
import { useBettingKeepAlive } from './useBettingKeepAlive';
import { BettingIframe, BettingWrapper } from './BettingWrapper';
import type { BettingClientErrorEvent } from './bettingErrorEvents';
import { BettingClientError } from './BettingClientError';

type FtwEvent =
  | { readonly type: 'ftw:load' }
  | { readonly type: 'ftw:render' }
  | { readonly type: 'ftw:routeChange'; readonly detail: RouteChangeEventDetails };

interface RouteChangeEventDetails {
  canonicalPath: string; // localized url
  path: string; // url
  pathname: string; // localized url
  hash: string;
  title: string;
  state: {
    path: string; // url
  };
  querystring: string;
  params: {
    [index: number]: string; // url
    type: string;
    tab: string;
    sport: string;
    season: string;
    status: string;
    buyin?: unknown;
  };
  query: Record<string, unknown>;
  previousContext: Omit<RouteChangeEventDetails, 'previousContext'> & {
    init: boolean;
  };
}

// @ts-expect-error We don't use this type, but it's useful to have it here for reference
// https://docs.scoutgaminggroup.com/customer/api/frontend
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type ScoutGamingOptions = {
  // Which environment to load
  environment: 'staging' | 'production';

  // Any ISO 4217 supported code
  currency: string;

  // customer
  whiteLabel: string;

  // empty string when not logged in
  token: string;

  // Should be the base path to the game. For instance /sports/fantasy/. Base path should always have a trailing slash.
  basePath: string;

  // URL to game server.
  root: string;

  // URL to web socket server.
  ws: string;

  // URL to assets(icons etc).
  assetHost: string;

  hashbang?: boolean;

  // cs - Czech
  // de - German
  // el - Greek
  // en - English
  // es - Spanish
  // et - Estonian
  // fi - Finnish
  // hi - Hindi
  // hy - Armenian
  // id - Indonesian
  // it - Italian
  // km - Khmer
  // ko - Korean
  // lv - Latvian
  // nl - Dutch
  // no - Norwegian
  // pl - Polish
  // pt - Portuguese
  // ro - Romanian
  // ru - Russian
  // se - se
  // sk - Slovak
  // sv - Swedish
  // th - Thai
  // tr - Turkish
  // vi - Vietnamese
  // zh - Chinese
  lang: string;
};

const HTML_PATH = '/static/betting-clients/scout-gaming/scout-gaming-client.html';

export const IframedScoutGamingBettingClient: FC = () => {
  const { t } = useTranslation('betting');
  const router = useRouter();
  const { keepAlive } = useBettingKeepAlive();
  const isLoggedIn = useIsLoggedIn();
  const initialHash = useRef(location.hash);
  useViewportHeight();

  const [error, setError] = useState<BettingClientErrorEvent>();

  const onLoad = (e: SyntheticEvent<HTMLIFrameElement, Event>) => {
    const iframeWindow = e.currentTarget.contentWindow;
    if (iframeWindow == null) {
      return;
    }

    const onMessage = (event: MessageEvent<FtwEvent | BettingClientErrorEvent>) => {
      const { origin, data } = event;

      if (origin !== location.origin) {
        return;
      }

      keepAlive();

      if ('type' in data) {
        if (data.type === 'ftw:routeChange') {
          const hash = data.detail.pathname.replace(HTML_PATH, '').slice(1);

          dispatchTrackingEvent('pageview', {
            page: {
              path: data.detail.pathname,
              title: data.detail.title,
              type: router.pathname,
            },
          });

          router.replace({ hash }, undefined, { shallow: true }).catch(() => {
            // Workaround for https://github.com/vercel/next.js/issues/37362
          });
        }
      }

      if ('name' in data && data.name === 'error') {
        if (data.errorCode === 'blockedTurnoverCategory') {
          data.provider = 'SCOUT_GAMING';
        }
        setError(data);
      }
    };

    iframeWindow.addEventListener('message', onMessage);
  };

  if (error != null) {
    return <BettingClientError error={error} />;
  }

  return (
    <BettingWrapper className="full-width">
      <BettingIframe
        title={t('page-title.sports-betting')}
        onLoad={onLoad}
        src={`${HTML_PATH}?language=${router.locale}${initialHash.current}`}
        key={`${router.locale}-${isLoggedIn}`}
      />
    </BettingWrapper>
  );
};
