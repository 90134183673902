import { useMemo } from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import { camelCase } from '@pafcloud/util';
import { useFlowRouter } from '@pafcloud/flow-router';
import type { FlowType } from '@pafcloud/flow-router';
import type { useGetFlowUrl_button$key } from './__generated__/useGetFlowUrl_button.graphql';

const useGetFlowUrlFragment = graphql`
  fragment useGetFlowUrl_button on Button {
    bonusTermsDocumentId
    flow
    customLink
    playerProgramId
    programExternalId
    promotion {
      promotionId
      customSlug
      customFlowTitle
    }
    calendarPromotion {
      slug
    }
  }
`;

type Props = {
  data: useGetFlowUrl_button$key;
};

export const useGetFlowUrl = ({ data }: Props) => {
  const button = useFragment(useGetFlowUrlFragment, data);

  const { getFlowUrl } = useFlowRouter();

  return useMemo(() => {
    if (button.flow === 'BONUS') {
      if (button.programExternalId) {
        return getFlowUrl('bonus', {
          programExternalId: button.programExternalId,
        });
      }

      if (button.playerProgramId) {
        return getFlowUrl('bonus', {
          playerProgramId: button.playerProgramId,
        });
      }

      return null;
    }

    if (button.flow === 'PROMOTION' && button.promotion) {
      const { promotion } = button;

      if (promotion.customSlug) {
        return getFlowUrl('promotion', {
          promotionSlug: promotion.customSlug,
          title: promotion.customFlowTitle ?? undefined,
        });
      }

      return getFlowUrl('promotion', {
        promotionId: promotion.promotionId,
        title: promotion.customFlowTitle ?? undefined,
      });
    }

    if (button.flow === 'CALENDAR_PROMOTION' && button.calendarPromotion) {
      const { calendarPromotion } = button;

      return getFlowUrl('calendarPromotion', {
        calendarSlug: calendarPromotion.slug,
      });
    }

    if (button.flow === 'BONUS_TERMS' && button.bonusTermsDocumentId) {
      return getFlowUrl('bonusTerms', {
        id: button.bonusTermsDocumentId,
      });
    }

    if (button.customLink) {
      return button.customLink;
    }

    // 'link' isn't actually a flow, but is selected as-a-flow in Sanity
    return getFlowUrl(camelCase(button.flow) as FlowType);
  }, [button, getFlowUrl]);
};
