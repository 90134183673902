import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { RichTextBlock } from '@pafcloud/rich-text';
import type { BonusTermsBlock_block$key } from './__generated__/BonusTermsBlock_block.graphql';

const blockFragment = graphql`
  fragment BonusTermsBlock_block on BonusTermsBlock {
    terms {
      ...RichTextBlock_block
    }
  }
`;

type Props = {
  block: BonusTermsBlock_block$key;
};

const BonusTermsBlock: FC<Props> = (props) => {
  const block = useFragment(blockFragment, props.block);

  if (block.terms == null) {
    return null;
  }

  return (
    <RichTextBlock
      block={block.terms}
      overrides={{
        types: { richTextImage: () => null },
      }}
    />
  );
};

export default BonusTermsBlock;
