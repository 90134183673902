import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { Color } from '@pafcloud/style';

const backgroundAnimation = keyframes({
  from: {
    backgroundPositionX: '200%',
  },
  to: {
    backgroundPositionX: '-200%',
  },
});

export const SkeletonContent = styled.div({
  color: '#0000', // 0% opacity hides the text.
  backgroundImage: `linear-gradient(95deg, ${Color.TextColors.BodyText}10 2%, ${Color.TextColors.BodyText}60 18%, ${Color.TextColors.BodyText}10 25%)`,
  borderRadius: Color.CornerRadius.Small,
  backgroundPositionX: '-100%',
  backgroundSize: '200% 100%',
  animation: `2s ${backgroundAnimation} linear infinite`,
  opacity: 0.6,
});

export const SkeletonBlock = styled(SkeletonContent)({
  width: '100%',
  height: '15vh',
  animationDuration: '4s',
});
