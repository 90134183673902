import type { CSSObject } from '@emotion/react';

export const mediaStyle: CSSObject = {
  objectPosition: '50% 50%',
  display: 'block',
  minWidth: '100%',
  maxWidth: '100%',
  minHeight: '100%',
  maxHeight: '100%',
  objectFit: 'cover',
};
