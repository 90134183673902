import { Color } from '@pafcloud/style';

const OddsAndBetting = {
  Time: `${Color.Hero.Text}c0`,
  Heading: Color.Hero.Heading,
  TeamNameText: Color.Hero.Heading,
  EventInfo: Color.Hero.Heading,

  OddsButtonBackground: 'rgba(0, 0, 0, 0.6)',
  OddsButtonBackgroundHover: 'rgba(0, 0, 0, 0.8)',
  OddsButtonBackgroundActive: 'rgba(0, 0, 0, 1)',
  OddsButtonText: '#fff',

  LiveBorder: `1px solid ${Color.Surface.Base.Foreground}20`,
  LiveBackground: Color.Surface.Base.Background,
  LiveText: Color.Surface.Base.Foreground,
  LiveIcon: 'red',
};

const FocusContent = {
  FrameBorder: Color.Primitive.Gradient,
  GameTagSeparator: Color.Primitive.Accent,
};

const DepositForm = {
  QuickButtons: undefined,
  QuickButtonsHover: undefined,
};

export const Colors = {
  BoxBackground: Color.Surface.Base.Background,
  BoxTextOverride: Color.Surface.Base.Foreground,

  Heading: Color.Hero.Heading,
  Text: Color.Hero.Text,
  TextHighlight: Color.Hero.TextHighlight,
  TextShadow: `0 1px 0 rgba(0,0,0,0.5)`,

  CountdownBackground: 'rgba(0,0,0,0.5)', // Temporary color, the Countdown component need a new design

  OddsAndBetting,
  FocusContent,
  DepositForm,
};
