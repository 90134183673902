import type { FC } from 'react';
import { Section, Image } from '@pafcloud/base-components';
import type { Icon as IconType } from '@pafcloud/icon';
import type { Layout } from './InfoCard';
import { InfoCard, ContainerIcon, ImageWrapper, Text, Description, Heading } from './InfoCard';
import { InformationContainerColors } from './information-container-colors';

type InformationContainerProps = {
  layout: Layout;
  heading: string;
  description: string;
  image?: {
    src: string | null;
    altText: string | null;
    width?: number;
    height?: number;
  };
  portraitIcon?: string | null;
};

export const InformationContainer: FC<InformationContainerProps> = ({
  heading,
  description,
  portraitIcon,
  image,
  layout,
}) => {
  return (
    <InfoCard layout={layout}>
      <Section>
        {portraitIcon && (
          <ContainerIcon
            name={portraitIcon as IconType}
            size="2.5rem"
            layout={layout}
            color={InformationContainerColors.Icon}
          />
        )}
        {!portraitIcon && image?.src && (
          <ImageWrapper>
            <Image src={image.src} alt={image.altText ?? ''} width={image.width ?? 48} height={image.height ?? 48} />
          </ImageWrapper>
        )}
        <Text layout={layout}>
          <Heading>{heading}</Heading>
          <Description>{description}</Description>
        </Text>
      </Section>
    </InfoCard>
  );
};
