/**
 * @generated SignedSource<<05ac6800206af08aca3b8f726f0ccd0f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PokerLauncherItem_data$data = {
  readonly backgroundUrl: string;
  readonly enablePopUpReminder: string | null;
  readonly heading: string | null;
  readonly info: string | null;
  readonly maintenance: {
    readonly message: string | null;
    readonly mode: boolean;
  } | null;
  readonly playInBrowserButton: string;
  readonly tagline: string | null;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"PokerAppStoreLink_data" | "PokerDownloadButton_data" | "PokerGooglePlayLink_data">;
  readonly " $fragmentType": "PokerLauncherItem_data";
};
export type PokerLauncherItem_data$key = {
  readonly " $data"?: PokerLauncherItem_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"PokerLauncherItem_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PokerLauncherItem_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "heading",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tagline",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "info",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backgroundUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "playInBrowserButton",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enablePopUpReminder",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Maintenance",
      "kind": "LinkedField",
      "name": "maintenance",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "message",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PokerDownloadButton_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PokerAppStoreLink_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PokerGooglePlayLink_data"
    }
  ],
  "type": "PokerLauncher",
  "abstractKey": null
};

(node as any).hash = "f79a84d74cdea12c50f5642d840716dc";

export default node;
