import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { MultiplayerBingoList } from '@pafcloud/block-components';
import type { MultiplayerBingoListBlock_block$key } from './__generated__/MultiplayerBingoListBlock_block.graphql';

const blockFragment = graphql`
  fragment MultiplayerBingoListBlock_block on MultiplayerBingoListBlock {
    title
    list {
      ...MultiplayerBingoList_bingoGameList
    }
  }
`;

type Props = {
  block: MultiplayerBingoListBlock_block$key;
};

const MultiplayerBingoListBlock: FC<Props> = (props) => {
  const block = useFragment(blockFragment, props.block);

  return <MultiplayerBingoList title={block.title} bingoGameList={block.list} />;
};

export default MultiplayerBingoListBlock;
