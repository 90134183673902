/**
 * @generated SignedSource<<b36dcb95cff17e1fe0ad07f113afdbe8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TableBlock_block$data = {
  readonly tableHeaderRow: boolean;
  readonly tableRows: ReadonlyArray<ReadonlyArray<string>>;
  readonly " $fragmentType": "TableBlock_block";
};
export type TableBlock_block$key = {
  readonly " $data"?: TableBlock_block$data;
  readonly " $fragmentSpreads": FragmentRefs<"TableBlock_block">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TableBlock_block",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tableHeaderRow",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tableRows",
      "storageKey": null
    }
  ],
  "type": "TableBlock",
  "abstractKey": null
};

(node as any).hash = "2abf6a9138bd046776761a53a237a03f";

export default node;
