import styled from '@emotion/styled';

export const GridList = styled.div({
  display: 'grid',
  gridAutoRows: 'max-content',
  gridTemplateColumns: `repeat(auto-fill, minmax(max(280px, calc(20% - 16px)), 1fr))`,
  justifyItems: 'stretch',
  justifyContent: 'stretch',
  width: '100%',
  gap: 16,
  padding: 0,
});
